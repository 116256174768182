import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import styles from './AppBar.module.scss';
import { Auth } from 'aws-amplify';
import { RiAccountCircleFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';

export default function PrimarySearchAppBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    // eslint-disable-next-line
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [userId, setUserId] = useState(null);
    const [showDefaultProfileImage, setShowDefaultProfileImage] =
        useState(null);
    const profileImageRef = useRef(null);
    const profileImageURL = (userId) =>
        `${
            process.env.REACT_APP_PROFILE_IMAGES_URL
        }/users/${userId}/picture/profile.jpg?${new Date().getTime()}`;

    const isMenuOpen = Boolean(anchorEl);
    // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const userProfileState = useSelector(
        (state) => state.reducer.initialData.userProfile
    );
    const userProfileImageState = useSelector(
        (state) => state.reducer.initialData.userProfileImage
    );
    const [newLogo, setNewLogo] = useState('');

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true
        })
            .then((result) => {
                setUserId(result.attributes.sub);
                if (result.attributes.zoneinfo) {
                    setNewLogo(
                        `${process.env.REACT_APP_PROFILE_IMAGES_URL}/org-info/${result.attributes.zoneinfo}/logo`
                    );
                }
            })
            .catch((error) => {
                setUserId(null);
            });

        return () => {};
    }, []);

    useEffect(() => {
        if (userProfileImageState.success) {
            let reader = new FileReader();
            reader.onload = (e) => {
                if (
                    !profileImageRef ||
                    !profileImageRef.current ||
                    !profileImageRef.current.src ||
                    showDefaultProfileImage
                ) {
                    setShowDefaultProfileImage(false);
                }
                profileImageRef.current.src = reader.result;
            };
            reader.readAsDataURL(userProfileState.data.profileImage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileImageState]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            className="user-menu-list"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id={menuId}
            getContentAnchorEl={null}
            keepMounted
            // transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem
                onClick={handleMenuClose}
                to="/user/profile"
                component={Link}>
                Your Profile
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <a
                    style={{ textDecoration: 'none' }}
                    href={process.env.REACT_APP_ROOT_URL + '/user/home'}>
                    Learner
                </a>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <a
                    style={{ textDecoration: 'none' }}
                    href="/"
                    onClick={async () => {
                        await signOut();
                    }}>
                    Log out
                </a>
            </MenuItem>
        </Menu>
    );

    return (
        <Grid
            container
            className={styles.AppBarContainer}
            justify="space-between"
            alignItems="center">
            <Grid container item xs={6} justify="flex-start">
                <Link to="/">
                    {newLogo ? (
                        <img
                            src={newLogo}
                            alt="headerlogo"
                            className={styles.AppBarLogoExternal}
                        />
                    ) : (
                        <img
                            src={require('../../assets/headerlogo.png')}
                            alt="headerlogo"
                            className={styles.AppBarLogo}
                        />
                    )}
                </Link>
            </Grid>

            <Grid container item xs={6} justify="flex-end" alignItems="center">
                <Link to="/dashboard/" className={styles.AppDashboardButton}>
                    <p>Dashboard</p>
                </Link>
                <div className={styles.AppBarIcon}>
                    <IconButton
                        aria-controls={menuId}
                        onClick={handleProfileMenuOpen}
                        style={{ color: '#F9B041', padding: 0 }}>
                        {showDefaultProfileImage || !userId ? (
                            <RiAccountCircleFill size={'1.8em'} />
                        ) : (
                            <img
                                alt={'User Profile'}
                                className={styles.ProfileImage}
                                ref={profileImageRef}
                                src={profileImageURL(userId)}
                                onError={() => {
                                    setShowDefaultProfileImage(true);
                                }}
                            />
                        )}
                    </IconButton>
                    {renderMenu}
                </div>

                <div className={styles.AppBarHamburger}>
                    <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="1" fill="#01539D" />
                        <circle cx="4" cy="12" r="1" fill="#01539D" />
                        <circle cx="4" cy="20" r="1" fill="#01539D" />
                        <path
                            d="M7.99554 4.25H22.0045"
                            stroke="#01539D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M7.99554 12.25H22.0045"
                            stroke="#01539D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M7.99554 20.25H22.0045"
                            stroke="#01539D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            </Grid>
        </Grid>
    );
}
