import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './OverviewTab.module.css';
import { Grid } from '@material-ui/core';
import { LineChart } from '../Shared/UI';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { GridOverlay } from '@material-ui/data-grid';
import { BiInfoCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { getPresentations } from 'redux/action/Index';
import { commonUTCToLocalDateFormat } from 'utils/date';
import { CenterWrapper } from 'components/Shared/HOC';
import { P } from 'components/Shared/Theme';
import Loader from 'components/LoaderComp/LoaderComp';
import { isNumberValueEmpty } from 'utils/Numbers';
import { getShortDurationFromSeconds } from 'utils/time';
import _ from 'lodash';
import defaultPresentationThumbnailImage from '../../assets/images/samples/sample-dashboard-video.png';
const presentationThumbnailImage = (id) =>
    `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-columnHeader': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: '#283E59',
            fontWeight: 300,
            fontSize: 12,
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center',
            outline: 'none !important'
        },
        '& .MuiDataGrid-cell--withRenderer': {
            justifyContent: 'center !important',
            outline: 'none !important'
        }
    },
    inputSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        },
        color: '#283E59',
        fontSize: '18px',
        fontWeight: 600
    }
}));

const OverviewTab = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const presentationsState = useSelector(
        (state) => state.reducer.initialData.presentationsData
    );
    const [viewsDateRange, setViewsDateRange] = useState('');

    useEffect(() => {
        dispatch(getPresentations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [viewsReports, setViewsReports] = useState([
        { label: 'Total Views', viewerCount: '-' },
        { label: 'Total watch time (Hours:Minutes)', viewerCount: 0 }
    ]);

    const viewsReportDurations = [
        { label: 'All Time', value: '' },
        { label: 'Last 30 Days', value: 'last30Days' }
    ];

    const onViewReportsDurationChangeHandler = (evt) => {
        setViewsDateRange(evt.target.value);
        processLineChartData(presentationData);
        switch (evt.target.value) {
            case 'last30Days':
                {
                    processLast30DaysLineChartData();
                }
                break;
        }
    };

    const processLast30DaysLineChartData = () => {
        let tempLineChartData = _.cloneDeep(lineChartData);
        const lastPresentationViewed = tempLineChartData.slice(
            Math.max(tempLineChartData.length - 1, 0)
        );

        if (lastPresentationViewed && lastPresentationViewed.length === 1) {
            const lastPresentationViewDate = new Date(
                lastPresentationViewed[0].time
            );
            const lastPresentationViewDateTime =
                lastPresentationViewDate.getTime();
            const last30DaysDate = new Date(
                lastPresentationViewDate.setDate(
                    lastPresentationViewDate.getDate() - 30
                )
            );
            const last30DaysDateTime = last30DaysDate.getTime();

            tempLineChartData = tempLineChartData.filter((chartData) => {
                const elementDateTime = new Date(chartData.time).getTime();
                return (
                    elementDateTime <= lastPresentationViewDateTime &&
                    elementDateTime > last30DaysDateTime
                );
            });
            setLineChartData(tempLineChartData);
        }
    };

    const chartOptions = {
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day'
                    },
                    gridLines: {
                        display: true
                    }
                }
            ],
            yAxes: [
                {
                    // ticks: {
                    //     min: 0
                    // },
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        beginAtZero: false,
                        callback: function (value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        }
                    }
                }
            ]
        }
    };

    const columns = [
        {
            field: 'presentation',
            headerName: 'Module',
            width: 760,
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    style={{ padding: 5 }}
                    alignItems="center">
                    <Grid item lg={1}>
                        <P
                            inline="true"
                            className={styles.PresentationColumnId}>
                            {params.row.index}
                        </P>
                    </Grid>
                    <Grid item lg={2}>
                        <div className={styles.DatagridPresentationImage}>
                            <img
                                style={{
                                    width: 'auto',
                                    height: '100%',
                                    maxWidth: '100%',
                                    maxHeight: 65
                                }}
                                alt="Module Thumbnail"
                                src={presentationThumbnailImage(params.row.id)}
                                onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src =
                                        defaultPresentationThumbnailImage;
                                }}></img>
                        </div>
                    </Grid>
                    <Grid item lg={6}>
                        <Box component="div">
                            <P
                                inline="true"
                                className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                {params.row.title}
                            </P>
                            <P
                                inline="true"
                                className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanDate}`}>
                                {params.row.createdDate
                                    ? commonUTCToLocalDateFormat(
                                          params.row.createdDate
                                      )
                                    : '-'}
                            </P>
                        </Box>
                    </Grid>
                </Grid>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 200,
            renderCell: (params) => (
                <P inline="true" className={styles.DurationSpan}>
                    {params.row.videoDuration ? params.row.videoDuration : '-'}
                </P>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'views',
            headerName: 'Views',
            width: 200,
            renderCell: (params) => (
                <P inline="true" className={styles.ViewsSpan}>
                    {params.row.viewCount ? params.row.viewCount : '-'}
                </P>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    const getLatestPresentations = (presentationData) => {
        let presentations = [];
        let topPresentations = [];

        if (
            presentationData &&
            presentationData instanceof Array &&
            presentationData.length > 0
        ) {
            presentations = _.cloneDeep(presentationData);
            presentations = getDaysAgoData(presentations, 30);
            topPresentations = presentations.slice(-3).reverse();
        }

        return topPresentations;
    };

    const getDaysAgoData = (data, daysAgo) => {
        let currentDate = new Date();
        let daysAgoDate = new Date(
            Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - daysAgo
            )
        );

        const filteredData = data.filter(
            (item) => new Date(item.createdDate) >= daysAgoDate
        );
        const cleanedData = filteredData.map((row) => ({
            ...row,
            viewCount: +row.viewCount ? +row.viewCount : 0
        }));
        return cleanedData.sort((a, b) => +a.viewCount - +b.viewCount);
    };

    const { data: presentationData, loading, success } = presentationsState;

    const rows = getLatestPresentations(presentationData);
    const [lineChartData, setLineChartData] = useState([]);

    const processLineChartData = (presentationData) => {
        setLineChartData([]);
        let tempLineChartData = [];
        const tempViewsReports = _.cloneDeep(viewsReports);
        tempViewsReports[1].viewerCount = 0;

        const presData = _.cloneDeep(presentationData) || [];
        const cleanup = presData.filter((item) => {
            if (item.geoDataSet) {
                return true;
            }
            return false;
        });

        let data = [];

        cleanup.forEach((item) => {
            const durations = item.videoDuration.split(':');
            durations[0] = +durations[0] * 60 * 60;
            durations[1] = +durations[1] * 60;
            const duration = durations[0] + durations[1];
            tempViewsReports[1].viewerCount += item.viewCount * duration;

            if (item.geoDataSet.length > 0) {
                for (let x of item.geoDataSet) {
                    try {
                        const parsed = JSON.parse(x);
                        if (parsed.city) {
                            data.push(parsed);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            } else {
                try {
                    const parsed = JSON.parse(item.geoDataSet);
                    if (parsed.city) {
                        data.push(parsed);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });

        let geoDataForLineChart = _.cloneDeep(data);
        geoDataForLineChart = geoDataForLineChart.map((geoData) => ({
            ...geoData,
            id: commonUTCToLocalDateFormat(geoData.id)
        }));

        let tempLineChartObject = {};
        for (let index = 0; index < geoDataForLineChart.length; index++) {
            const element = geoDataForLineChart[index];
            if (tempLineChartObject[element.id]) {
                tempLineChartObject[element.id].value++;
            } else {
                tempLineChartObject[element.id] = element;
                tempLineChartObject[element.id].time = element.id;
                tempLineChartObject[element.id].value = 1;
            }
        }

        tempLineChartData = Object.values(tempLineChartObject);
        tempLineChartData.sort((a, b) => {
            const dateA = new Date(a.time);
            const dateB = new Date(b.time);
            return dateA - dateB;
        });

        setLineChartData(tempLineChartData);

        tempViewsReports[0].viewerCount = data.length;
        tempViewsReports[1].viewerCount = getShortDurationFromSeconds(
            tempViewsReports[1].viewerCount
        );

        tempViewsReports[0].viewerCount = isNumberValueEmpty(
            tempViewsReports[0].viewerCount
        )
            ? '-'
            : tempViewsReports[0].viewerCount;
        tempViewsReports[1].viewerCount = !tempViewsReports[1].viewerCount
            ? '-'
            : tempViewsReports[1].viewerCount;
        setViewsReports(tempViewsReports);
    };

    useEffect(() => {
        processLineChartData(presentationData);
    }, [presentationData]);

    const CustomNoRowsOverlay = () => {
        const classes = useStyles();

        return (
            <GridOverlay className={classes.root}>
                <div className="container mx-auto -lg:px-8 -md:px-4 flex flex-col justify-center items-center max-w-screen-lg text-center">
                    <img
                        className={`my-12`}
                        src={require('../../assets/images/no-result/lady-standing-3.svg')}
                        alt="No Result User"
                    />

                    <p className={`${styles.NoResultMessage} text-center`}>
                        You dont have any modules, Click
                        <br />
                        "New Module" to record one
                    </p>
                </div>
            </GridOverlay>
        );
    };

    let containerUI = (
        <CenterWrapper>
            <Loader />
        </CenterWrapper>
    );

    if (!loading && success) {
        containerUI = (
            <Grid container direction="row" justify="center">
                <Grid item lg={12}>
                    <p className={styles.Title}>Reports</p>
                </Grid>

                <Grid item lg={12} className={styles.ReportContainer}>
                    <Grid container direction="row" justify="center">
                        <Grid item lg={12}>
                            <p className={styles.ContainerTitle}>Views</p>
                        </Grid>

                        <Grid item lg={8}>
                            {lineChartData &&
                            lineChartData instanceof Array &&
                            lineChartData.length > 0 ? (
                                <LineChart
                                    options={chartOptions}
                                    data={lineChartData}
                                    title={'Views'}
                                    color="#4184F3"
                                />
                            ) : (
                                <div className={styles.NoViewersContainer}>
                                    <div
                                        className={`${styles.NoViewersIconContainer}`}>
                                        <BiInfoCircle
                                            size={'1.8em'}
                                            color={'rgba(40, 62, 89, 0.54)'}
                                        />
                                    </div>

                                    <div
                                        className={`${styles.NoViewersMessageContainer}`}>
                                        <P
                                            inline="true"
                                            className={`${styles.NoViewersMessage}`}>
                                            No Viewers yet
                                        </P>
                                    </div>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={4} lg={4} style={{ paddingLeft: 20 }}>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    native
                                    value={viewsDateRange}
                                    onChange={
                                        onViewReportsDurationChangeHandler
                                    }
                                    className={classes.inputSelect}
                                    inputProps={{
                                        name: 'viewsReportDurations',
                                        id: 'outlined-age-native-simple'
                                    }}
                                    style={{
                                        color: '#283E59',
                                        fontSize: '18px',
                                        fontWeight: 600
                                    }}>
                                    {viewsReportDurations.map(
                                        (viewsReportDuration, index) => (
                                            <option
                                                className={styles.ReportsSelect}
                                                key={index}
                                                value={
                                                    viewsReportDuration.value
                                                }>
                                                {viewsReportDuration.label}
                                            </option>
                                        )
                                    )}
                                </Select>
                            </FormControl>

                            <ul style={{ padding: 0 }}>
                                {viewsReports.map((viewerReport, index) => (
                                    <li
                                        key={index}
                                        className={styles.ReportsDetails}>
                                        <P inline="true">
                                            {viewerReport.label}
                                        </P>
                                        <P inline="true">
                                            {viewerReport.viewerCount}
                                        </P>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    lg={12}
                    className={`${styles.TopPresentationContainer}`}>
                    <Grid container direction="row" justify="center">
                        <Grid item lg={12}>
                            <P inline="true" className={styles.ContainerTitle}>
                                Top Modules
                            </P>
                        </Grid>

                        <Grid item lg={12}>
                            <div
                                style={{ height: 325, width: '100%' }}
                                className={classes.root}>
                                <DataGrid
                                    className={`${styles['MuiDataGrid-root']}`}
                                    rows={rows}
                                    columns={columns}
                                    rowHeight={75}
                                    hideFooter={true}
                                    showCellRightBorder={false}
                                    showColumnRightBorder={false}
                                    loading={loading}
                                    disableSelectionOnClick
                                    components={{
                                        NoRowsOverlay: CustomNoRowsOverlay
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return containerUI;
};

export default OverviewTab;
