import axios from 'axios';
import { API } from 'aws-amplify';
import { getFileExtension, acceptedTypes, typeMap } from 'utils/files';
export { getPresentationReviews } from 'redux/action/presentations/presentations';
export const SET_DOCUMENT_PAGE_COUNT = 'setDocumentPageCount';
export const ON_PAGE_CHANGE_CLICK = 'onPageChangeClick';
export const CURRENT_TIMESTAMP = 'currentTimeStamp';
export const ON_SAVE_RECORDED_VIDEO = 'onSaveRecordedVideo';
export const ON_SAVE_RECORDED_VIDEO_START = 'onSaveRecordedVideoStart';
export const ON_SAVE_RECORDED_VIDEO_SUCCESS = 'onSaveRecordedVideoSuccess';
export const ON_SAVE_RECORDED_VIDEO_FAIL = 'onSaveRecordedVideoFail';
export const PAGE_UPDATE = 'onPageUpdate';
export const UPLOAD_FILE_TO_S3_START = 'uploadFileToS3Start';
export const UPLOAD_FILE_TO_S3_FAIL = 'uploadFileToS3Fail';
export const UPLOAD_FILE_TO_S3_SUCCESS = 'uploadFileToS3Success';
export const NO_CHANGE = 'noChange';
export const S3_VIDEO_DATA = 's3VideoData';
export const S3_VIDEO_UPLOAD_PROGRESS = 's3VideoUploadProgress';
export const ADD_NEW_KEYWORD = 'addNewKeyword';
export const ON_REMOVE_KEYWORD = 'onRemoveKeyWord';
export const ADD_NEW_CATEGORY = 'addNewCategory';
export const ON_INPUT_HANDLE_CHANGE = 'onInputHandleChange';
export const ON_CONDITION_VARIABLE = 'onConditionVariable';
export const ON_TITLE_CHANGE = 'onTitleChange';
export const ON_PRESENTATION_LENGTH_CHANGE = 'onPresentationLengthChange';
export const ON_CATEGORY_PAGE_CHANGE = 'onCategoryPageChange';
export const SET_PROFILE_PICTURE = 'setProfilepicture';
export const GET_PRESENTATIONS_DATA = 'getPresentationsData';
export const GET_PRESENTATIONS_DATA_SUCCESS = 'getPresentationsDataSuccess';
export const GET_PRESENTATIONS_DATA_START = 'getPresentationsDataStart';
export const GET_PRESENTATIONS_DATA_FAIL = 'getPresentationsDataFail';
export const PRESENTATION_DATA = 'presentationData';
export const PRESENTATION_TAB_HANDLE = 'onPresentationTabHandleChange';
export const SET_VIDEO_PREVIEW_TO_FALSE = 'setVideoPreviewToFalse';
export const RESET_RECORDING_STATE = 'resetRecordingState';
export const ON_LEARNING_OBJECTIVES_CHANGE = 'onLearningObjectivesChange';
export const ON_REFERENCES_CHANGE = 'onReferencesChange';
export const ON_CONFLICTS_CHANGE = 'onConflictsChange';
export const ON_PRESENTATION_RESET = 'onPresentationReset';
export const GET_PRESENTATION_DATA_START = 'onGetPresentationDataStart';
export const GET_PRESENTATION_DATA_SUCCESS = 'onGetPresentationDataSuccess';
export const GET_PRESENTATION_DATA_FAIL = 'onGetPresentationDataFail';
export const GET_PRESENTATION_DATA_RESET = 'onGetPresentationDataReset';
export const UPDATE_PRESENTATION_INFO_START = 'onUpdatePresentationInfoStart';
export const UPDATE_PRESENTATION_INFO_SUCCESS =
    'onUpdatePresentationInfoSuccess';
export const UPDATE_PRESENTATION_INFO_FAIL = 'onUpdatePresentationInfoFail';
export const UPDATE_PRESENTATION_INFO_RESET = 'onUpdatePresentationInfoReset';
export const ON_PRESENTATION_INFO_RESET = 'onPresentationInfoReset';
export const ON_PAGE_CHANGE_TIMESTAMP_RECORD = 'onPageChangeTimeStampRecord';
export const ON_RESET_RECORDED_TIMESTAMP = 'onResetRecordedTimeStamp';
export const ON_SET_RECORDED_VIDEO_DURATION = 'onSetRecordedVideoDuration';
export const ON_RESET_RECORDED_VIDEO_DURATION = 'onResetRecordedVideoDuration';
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const REMOVE_PRESENTATION_START = 'removePresentationStart';
export const REMOVE_PRESENTATION_SUCCESS = 'removePresentationSuccess';
export const REMOVE_PRESENTATION_FAIL = 'removePresentationFail';
export const REMOVE_PRESENTATION_RESET = 'removePresentationReset';
export const RESET_PDF_PAGE_NUMBER = 'resetPDFPageNumber';
export const ON_PRESENTATION_INFO_SUBMIT_START =
    'onPresentationInfoSubmitStart';
export const ON_PRESENTATION_INFO_SUBMIT_SUCCESS =
    'onPresentationInfoSubmitSuccess';
export const ON_PRESENTATION_INFO_SUBMIT_FAIL = 'onPresentationInfoSubmitFail';
export const ON_PRESENTATION_FILE_SELECTOR_ERROR =
    'onPresentationFileSelectorError';
export const ON_PRESENTATION_FILE_SELECTOR_RESET =
    'onPresentationFileSelectorReset';
export const ON_STORE_PROFILE_PICTURE_START = 'onStoreProfilePictureStart';
export const ON_STORE_PROFILE_PICTURE_SUCCESS = 'onStoreProfilePictureSuccess';
export const ON_STORE_PROFILE_PICTURE_FAIL = 'onStoreProfilePictureFail';
export const ON_STORE_PROFILE_PICTURE_RESET = 'onStoreProfilePictureReset';

export const ON_STORE_PROFILE_START = 'onStoreProfileStart';
export const ON_STORE_PROFILE_SUCCESS = 'onStoreProfileSuccess';
export const ON_STORE_PROFILE_FAIL = 'onStoreProfileFail';
export const ON_STORE_PROFILE_RESET = 'onStoreProfileReset';

export const ON_UPLOAD_REFERENCE_FILE_START = 'onUploadReferenceFileStart';
export const ON_UPLOAD_REFERENCE_FILE_SUCCESS = 'onUploadReferenceFileSuccess';
export const ON_UPLOAD_REFERENCE_FILE_FAIL = 'onUploadReferenceFileFail';
export const ON_UPLOAD_REFERENCE_FILE_RESET = 'onUploadReferenceFileReset';
export const ON_UPLOAD_REFERENCE_FILE_PROGRESS_UPDATE =
    'onUploadReferenceFileProgressUpdate';

export const ON_UPLOAD_ALL_REFERENCE_FILES_START =
    'onUploadAllReferenceFilesStart';
export const ON_UPLOAD_ALL_REFERENCE_FILES_SUCCESS =
    'onUploadAllReferenceFilesSuccess';
export const ON_UPLOAD_ALL_REFERENCE_FILES_RESET =
    'onUploadAllReferenceFilesReset';

export const ON_DISCARD_UPLOADED_REFERENCE_FILES =
    'onDiscardUploadedReferenceFiles';

export const ON_REMOVE_FILE_FROM_REFERENCE_FILES =
    'onRemoveFileFromReferenceFiles';

export const ON_UPLOAD_PRESENTATION_FILE_START =
    'onUploadPresentationFileStart';
export const ON_UPLOAD_PRESENTATION_FILE_SUCCESS =
    'onUploadPresentationFileSuccess';
export const ON_UPLOAD_PRESENTATION_FILE_FAIL = 'onUploadPresentationFileFail';
export const ON_UPLOAD_PRESENTATION_FILE_RESET =
    'onUploadPresentationFileReset';
export const ON_UPLOAD_PRESENTATION_FILE_PROGRESS_UPDATE =
    'onUploadPresentationFileProgressUpdate';
export const ON_UPLOAD_ALL_PRESENTATION_FILES_START =
    'onUploadAllPresentationFilesStart';
export const ON_UPLOAD_ALL_PRESENTATION_FILES_SUCCESS =
    'onUploadAllPresentationFilesSuccess';
export const ON_UPLOAD_ALL_PRESENTATION_FILES_RESET =
    'onUploadAllPresentationFilesReset';
export const ON_DISCARD_UPLOADED_PRESENTATION_FILES =
    'onDiscardUploadedPresentationFiles';
export const ON_REMOVE_FILE_FROM_PRESENTATION_FILES =
    'onRemoveFileFromPresentationFiles';

export function noChange() {
    return {
        type: NO_CHANGE
    };
}

// User Profile - Start
export const getUserProfileStart = () => {
    return {
        type: GET_USER_PROFILE_START
    };
};

export const getUserProfileSuccess = (data) => {
    return {
        type: GET_USER_PROFILE_SUCCESS,
        payload: data
    };
};

export const getUserProfileFail = (error) => {
    return {
        type: GET_USER_PROFILE_FAIL,
        payload: { error }
    };
};

export const getUserProfile = () => {
    return (dispatch, getState) => {
        dispatch(getUserProfileStart());
        API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/info')
            .then((response) => {
                dispatch(getUserProfileSuccess(response));
            })
            .catch((error) => {
                dispatch(getUserProfileFail(error));
            });
    };
};
// User Profile - End

export function setVideoPreviewToFalse() {
    return {
        type: SET_VIDEO_PREVIEW_TO_FALSE
    };
}

export function resetRecordingState() {
    return {
        type: RESET_RECORDING_STATE
    };
}

export function setDocumentPageCount(value) {
    return {
        type: SET_DOCUMENT_PAGE_COUNT,
        payload: {
            value
        }
    };
}
export function onPageChangeClick(value, numPages) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_PAGE_CHANGE_CLICK,
            payload: {
                value,
                numPages
            }
        });
        //dispatch(currentTimeStamp());
    };
}

export const onPageChangeTimeStampRecordAction = (pageNumber, timestamp) => {
    return (dispatch, getState) => {
        dispatch({
            type: ON_PAGE_CHANGE_TIMESTAMP_RECORD,
            payload: {
                pageNumber,
                timestamp
            }
        });
    };
};

export const onResetRecordedTimeStampAction = () => {
    return (dispatch) => {
        dispatch({
            type: ON_RESET_RECORDED_TIMESTAMP
        });
    };
};

export const onResetPDFPageNumberAction = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_PDF_PAGE_NUMBER
        });
    };
};

export function onUploadFileToS3Success(value, pdfFileName) {
    return (dispatch, getState) => {
        dispatch({
            type: UPLOAD_FILE_TO_S3_SUCCESS,
            payload: {
                value,
                pdfFileName
            }
        });
    };
}

export function onUploadFileToS3Start() {
    return (dispatch, getState) => {
        dispatch({
            type: UPLOAD_FILE_TO_S3_START
        });
    };
}

export function onUploadFileToS3Fail(error) {
    return (dispatch, getState) => {
        dispatch({
            type: UPLOAD_FILE_TO_S3_FAIL,
            payload: { error }
        });
    };
}

export function currentTimeStamp(tStamp, recordData) {
    return (dispatch, getState) => {
        dispatch({
            type: CURRENT_TIMESTAMP,
            payload: {
                tStamp,
                recordData
            }
        });
    };
}

export function onSaveRecordedVideo(value, initRecordingPage, reload) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_SAVE_RECORDED_VIDEO,
            payload: {
                value,
                initRecordingPage,
                reload
            }
        });
        if (!reload) {
            dispatch(recoredVideoSend(value));
        }
    };
}

export function onPageUpdate(value) {
    return (dispatch, getState) => {
        dispatch({
            type: PAGE_UPDATE,
            payload: {
                value
            }
        });
    };
}

export function addNewKeyword(value) {
    return (dispatch, getState) => {
        dispatch({
            type: ADD_NEW_KEYWORD,
            payload: {
                value
            }
        });
    };
}

export function onRemoveKeyWord(value) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_REMOVE_KEYWORD,
            payload: {
                value
            }
        });
    };
}

export function addNewCategory(value) {
    return (dispatch, getState) => {
        dispatch({
            type: ADD_NEW_CATEGORY,
            payload: {
                value
            }
        });
    };
}

export function onInputHandleChange(value, id) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_INPUT_HANDLE_CHANGE,
            payload: { value, id }
        });
    };
}

export function onLearningObjectivesChange(value) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_LEARNING_OBJECTIVES_CHANGE,
            payload: { value }
        });
    };
}

export function onReferencesChange(value) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_REFERENCES_CHANGE,
            payload: { value }
        });
    };
}

export function onConflictsChange(value) {
    return (dispatch, getState) => {
        dispatch({
            type: ON_CONFLICTS_CHANGE,
            payload: {
                value
            }
        });
    };
}

export function onConditionVariable(id, value) {
    return {
        type: ON_CONDITION_VARIABLE,
        payload: {
            id,
            value
        }
    };
}

export function onTitleChange(value) {
    return {
        type: ON_TITLE_CHANGE,
        payload: {
            value
        }
    };
}

export function onPresentationLengthChange(value) {
    return {
        type: ON_PRESENTATION_LENGTH_CHANGE,
        payload: {
            value
        }
    };
}

export function s3VideoData(value) {
    return {
        type: S3_VIDEO_DATA,
        payload: {
            value
        }
    };
}

export function s3VideoUploadProgress(value) {
    return {
        type: S3_VIDEO_UPLOAD_PROGRESS,
        payload: {
            value
        }
    };
}

export function setProfilepicture(value) {
    return {
        type: SET_PROFILE_PICTURE,
        payload: {
            value
        }
    };
}

export function getPresentationsStart() {
    return {
        type: GET_PRESENTATIONS_DATA_START
    };
}

export function getPresentationsSuccess(value) {
    return {
        type: GET_PRESENTATIONS_DATA_SUCCESS,
        payload: value
    };
}

export function getPresentationsFail(error) {
    return {
        type: GET_PRESENTATIONS_DATA_FAIL,
        payload: error
    };
}

export function presentationData(value) {
    return {
        type: PRESENTATION_DATA,
        payload: {
            value
        }
    };
}

export function onSetRecordedVideoDurationAction(value) {
    return {
        type: ON_SET_RECORDED_VIDEO_DURATION,
        payload: value
    };
}

export function onResetRecordedVideoDurationAction() {
    return {
        type: ON_RESET_RECORDED_VIDEO_DURATION
    };
}

export function onCategoryPageChange(value) {
    return {
        type: ON_CATEGORY_PAGE_CHANGE,
        payload: {
            value
        }
    };
}

export function handleUpload(value) {
    return (dispatch, getState) => {
        if (value === 'upload') {
            dispatch(presentationInfoSend());
        } else if (value === 'save') {
            dispatch(onSaveUserProfileDetails());
            // dispatch(storeProfileDetails());
        }
    };
}

export function onSaveRecordedVideoStartAction() {
    return {
        type: ON_SAVE_RECORDED_VIDEO_START
    };
}

export function onSaveRecordedVideoSuccessAction(payload) {
    return {
        type: ON_SAVE_RECORDED_VIDEO_SUCCESS,
        payload
    };
}

export function onSaveRecordedVideoFailAction(error) {
    return {
        type: ON_SAVE_RECORDED_VIDEO_FAIL,
        payload: error
    };
}

export function onUploadReferenceFileStartAction(payload) {
    return {
        type: ON_UPLOAD_REFERENCE_FILE_START,
        payload
    };
}

export function onUploadReferenceFileSuccessAction(payload) {
    return {
        type: ON_UPLOAD_REFERENCE_FILE_SUCCESS,
        payload
    };
}

export function onUploadReferenceFileFailAction(fileName, error) {
    return {
        type: ON_UPLOAD_REFERENCE_FILE_FAIL,
        payload: { fileName, error }
    };
}

export function onUploadReferenceFileResetAction() {
    return {
        type: ON_UPLOAD_REFERENCE_FILE_RESET
    };
}

export function onUploadReferenceFileProgressUpdateAction(payload) {
    return {
        type: ON_UPLOAD_REFERENCE_FILE_PROGRESS_UPDATE,
        payload
    };
}

export function onUploadAllReferenceFilesStartAction() {
    return {
        type: ON_UPLOAD_ALL_REFERENCE_FILES_START
    };
}

export function onUploadAllReferenceFilesSuccessAction() {
    return {
        type: ON_UPLOAD_ALL_REFERENCE_FILES_SUCCESS
    };
}

export function onUploadAllReferenceFilesResetAction() {
    return {
        type: ON_UPLOAD_ALL_REFERENCE_FILES_RESET
    };
}

export function onDiscardUploadedReferenceFilesAction() {
    return {
        type: ON_DISCARD_UPLOADED_REFERENCE_FILES
    };
}

export function onRemoveFileFromReferenceFilesAction(payload) {
    return {
        type: ON_REMOVE_FILE_FROM_REFERENCE_FILES,
        payload
    };
}

export const onUploadPresentationReferenceFile = (files) => {
    return async (dispatch, getState) => {
        dispatch(onUploadAllReferenceFilesStartAction());
        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const file = files[fileIndex].file;

            dispatch(
                onUploadReferenceFileStartAction(`${files[fileIndex].fileName}`)
            );
            const state = getState();
            const { initialData } = state.reducer;
            const presentationId = initialData.pdfData.presentationId;

            try {
                const fileType = files[fileIndex].fileType;
                const response = await API.get(
                    process.env.REACT_APP_API_ENDPOINT_NAME,
                    `/presentation/${presentationId}/reference-upload?contentType=${fileType}`
                );

                await axios({
                    method: 'put',
                    url: response.uploadUrl,
                    data: file,
                    headers: { 'Content-Type': fileType },
                    onUploadProgress: (uploadedingFileProgress) => {
                        const progress =
                            (uploadedingFileProgress.loaded /
                                uploadedingFileProgress.total) *
                            100;
                        dispatch(
                            onUploadReferenceFileProgressUpdateAction(progress)
                        );
                    }
                });

                const { fileId, fileKey } = response;
                dispatch(
                    onUploadReferenceFileSuccessAction({
                        fileId,
                        fileKey
                    })
                );
            } catch (error) {
                dispatch(
                    onUploadReferenceFileFailAction(
                        files[fileIndex].fileName,
                        error
                    )
                );
            }
        }
        dispatch(onUploadAllReferenceFilesSuccessAction());
    };
};

export const recoredVideoSend = (value) => {
    return (dispatch, getState) => {
        const state = getState();
        const { initialData } = state.reducer;
        const presentationId = initialData.pdfData.presentationId;
        let videoData = value.data;

        dispatch(onSaveRecordedVideoStartAction());

        try {
            API.get(
                process.env.REACT_APP_API_ENDPOINT_NAME,
                `/presentation/${presentationId}/video-upload`
            )
                .then((response) => {
                    axios({
                        method: 'put',
                        url: response.uploadUrl,
                        data: videoData,
                        headers: { 'Content-Type': 'video/webm' },
                        onUploadProgress: (p) => {
                            console.log(p);
                            const progress = (p.loaded / p.total) * 100;
                            if (progress > 10) {
                                dispatch(s3VideoUploadProgress(progress));
                            }
                            if (progress === 100) {
                                dispatch(s3VideoUploadProgress(95));
                            }
                        }
                    })
                        .then(() => {
                            dispatch(
                                onSaveRecordedVideoSuccessAction(response)
                            );
                            dispatch(s3VideoData(response));
                            dispatch(timeStampSend(response));
                        })
                        .catch((error) => {
                            dispatch(onSaveRecordedVideoFailAction(error));
                        });
                })
                .catch((signedURLError) => {
                    dispatch(onSaveRecordedVideoFailAction(signedURLError));
                });
        } catch (error) {
            dispatch(onSaveRecordedVideoFailAction(error));
        }
    };
};

export function onPresentationReset() {
    return {
        type: ON_PRESENTATION_RESET
    };
}

// Upload Presentation file/video
export function onUploadPresentationFileStartAction(payload) {
    return {
        type: ON_UPLOAD_PRESENTATION_FILE_START,
        payload
    };
}

export function onUploadPresentationFileSuccessAction(payload) {
    return {
        type: ON_UPLOAD_PRESENTATION_FILE_SUCCESS,
        payload
    };
}

export function onUploadPresentationFileFailAction(fileName, error) {
    return {
        type: ON_UPLOAD_PRESENTATION_FILE_FAIL,
        payload: { fileName, error }
    };
}

export function onUploadPresentationFileResetAction() {
    return {
        type: ON_UPLOAD_PRESENTATION_FILE_RESET
    };
}

export function onUploadPresentationFileProgressUpdateAction(payload) {
    return {
        type: ON_UPLOAD_PRESENTATION_FILE_PROGRESS_UPDATE,
        payload
    };
}

export function onUploadAllPresentationFilesStartAction() {
    return {
        type: ON_UPLOAD_ALL_PRESENTATION_FILES_START
    };
}

export function onUploadAllPresentationFilesSuccessAction() {
    return {
        type: ON_UPLOAD_ALL_PRESENTATION_FILES_SUCCESS
    };
}

export function onUploadAllPresentationFilesResetAction() {
    return {
        type: ON_UPLOAD_ALL_PRESENTATION_FILES_RESET
    };
}

export function onDiscardUploadedPresentationFilesAction() {
    return {
        type: ON_DISCARD_UPLOADED_PRESENTATION_FILES
    };
}

export function onRemoveFileFromPresentationFilesAction(payload) {
    return {
        type: ON_REMOVE_FILE_FROM_PRESENTATION_FILES,
        payload
    };
}

export const uploadVideoEmbed = (embedLink) => {
    return async (dispatch, getState) => {
        dispatch(onUploadAllPresentationFilesStartAction());

        dispatch(onUploadPresentationFileStartAction(embedLink));

        try {
            let response = null;
            response = await API.post(
                process.env.REACT_APP_API_ENDPOINT_NAME,
                `/presentation/new-video-embed`,
                { body: { embedLink: embedLink } }
            );

            dispatch(
                onUploadPresentationFileSuccessAction({
                    ...response
                })
            );
        } catch (error) {
            dispatch(onUploadPresentationFileFailAction(embedLink, error));
        }

        dispatch(onUploadAllPresentationFilesSuccessAction());
    };
};

export const onUploadPresentationFile = (files) => {
    return async (dispatch, getState) => {
        dispatch(onUploadAllPresentationFilesStartAction());
        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const file = files[fileIndex].file;

            dispatch(
                onUploadPresentationFileStartAction(
                    `${files[fileIndex].fileName}`
                )
            );

            try {
                const fileName = files[fileIndex];
                let fileType = files[fileIndex].fileType;
                let response = null;

                if (files[fileIndex].isVideoType) {
                    response = await API.post(
                        process.env.REACT_APP_API_ENDPOINT_NAME,
                        `/presentation/new-video`,
                        { body: { contentType: `${fileType}` } }
                    );
                } else {
                    if (acceptedTypes.indexOf(fileType) === -1) {
                        const fileExtension = getFileExtension(fileName);
                        fileType = typeMap[fileExtension];
                    }

                    response = await API.post(
                        process.env.REACT_APP_API_ENDPOINT_NAME,
                        `/presentation/new`,
                        { body: { contentType: `${fileType}` } }
                    );
                }

                await axios({
                    method: 'put',
                    url: response.uploadUrl,
                    data: file,
                    headers: { 'Content-Type': fileType },
                    onUploadProgress: (uploadedingFileProgress) => {
                        const progress =
                            (uploadedingFileProgress.loaded /
                                uploadedingFileProgress.total) *
                            100;
                        dispatch(
                            onUploadPresentationFileProgressUpdateAction(
                                progress
                            )
                        );
                    }
                });

                dispatch(
                    onUploadPresentationFileSuccessAction({
                        ...response
                    })
                );
            } catch (error) {
                dispatch(
                    onUploadPresentationFileFailAction(
                        files[fileIndex].fileName,
                        error
                    )
                );
            }
        }
        dispatch(onUploadAllPresentationFilesSuccessAction());
    };
};

export const storePdfDocument = (value, pdfFileName) => {
    return (dispatch, getState) => {
        const fileName = pdfFileName;
        var formData = new FormData();
        formData.append('uploaded_file', value, fileName);

        const URL =
            process.env.REACT_APP_STORAGE_API_ENDPOINT_URL +
            '/presentation/new';

        dispatch(onUploadFileToS3Start());

        return axios({
            method: 'post',
            url: URL,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then((response) => {
                dispatch(onUploadFileToS3Success(response.data, pdfFileName));
            })
            .catch((error) => {
                dispatch(onUploadFileToS3Fail(error));
            });
    };
};

export const timeStampSend = (value) => {
    return (dispatch, getState) => {
        const state = getState();
        const { initialData } = state.reducer;
        const videoId = value.videoId;
        const rTimestamps = { timestamps: initialData.timestamp };

        const URL = `${process.env.REACT_APP_API_ENDPOINT_URL}/video/${videoId}/timestamps`;

        API.post(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            `/video/${videoId}/timestamps`,
            { body: rTimestamps }
        )
            .then((response) => {})
            .catch((error) => {
                console.log(error);
            });
    };
};

const getDurationFromSeconds = (sec_num) => {
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    return hours + ':' + minutes + ':' + seconds;
};

export function onPresentationInfoSubmitStart() {
    return {
        type: ON_PRESENTATION_INFO_SUBMIT_START
    };
}

export function onPresentationInfoSubmitSuccess(payload) {
    return {
        type: ON_PRESENTATION_INFO_SUBMIT_SUCCESS,
        payload
    };
}

export function onPresentationInfoSubmitFail(error) {
    return {
        type: ON_PRESENTATION_INFO_SUBMIT_FAIL,
        payload: error
    };
}

export const presentationInfoSend = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { initialData } = state.reducer;
        let presentationId = initialData.pdfData.presentationId;
        let videoForPresentationData = initialData.videoForPresentationData;
        if (!presentationId && videoForPresentationData.presentationId) {
            presentationId = videoForPresentationData.presentationId;
        }

        const presInfo = {
            categories: initialData.category.map(({ title }) => title),
            keywords: initialData.keywordsValue.map(({ word }) => word),
            description: initialData.description,
            title: initialData.videoTitle,
            purposeText: initialData.purpose,
            learningObjectives: [...initialData.learningObjectives],
            references: [...initialData.references],
            conflicts: [...initialData.conflicts],
            videoDuration: getDurationFromSeconds(
                initialData.recordedVideoDuration
            )
        };

        if (initialData.referenceFiles.length > 0) {
            presInfo['referenceFiles'] = [...initialData.referenceFiles];
        }

        dispatch(onPresentationInfoSubmitStart());
        return API.post(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            `/presentation/${presentationId}/info`,
            { body: presInfo }
        )
            .then((response) => {
                dispatch(onPresentationInfoSubmitSuccess(response));
                dispatch(onPresentationReset());
            })
            .catch((error) => {
                dispatch(onPresentationInfoSubmitFail(error));
                throw error;
            });
    };
};

export const getPresentations = () => {
    return (dispatch, getState) => {
        dispatch(getPresentationsStart());
        return API.get(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            '/user/presentations'
        )
            .then((response) => {
                const remap = response
                    ? response.map(({ pid: id, ...value }) => ({
                          id,
                          ...value
                      }))
                    : [];
                const sortedArray = remap.sort(function (a, b) {
                    return new Date(b.createdDate) - new Date(a.createdDate);
                });
                const addWatchTime = sortedArray.map((item) => {
                    if (item.videoDuration && item.viewCount) {
                        const td = item.videoDuration.split(':');
                        const mins = +td[0] * 60 + +td[1];

                        const watchTime = +(item.viewCount * mins);
                        //TODO fix avg watch time later
                        const avgWatchTime = +mins;

                        const newItem = {
                            watchTime: watchTime ? watchTime : '-',
                            avgWatchTime: avgWatchTime ? avgWatchTime : '-',
                            ...item
                        };
                        return newItem;
                    } else {
                        const newItem = {
                            watchTime: '-',
                            avgWatchTime: '-',
                            ...item
                        };
                        return newItem;
                    }
                });
                dispatch(getPresentationsSuccess(addWatchTime));
            })
            .catch((error) => {
                dispatch(getPresentationsFail(error));
            });
    };
};

export function onStoreProfilePictureStart() {
    return {
        type: ON_STORE_PROFILE_PICTURE_START
    };
}

export function onStoreProfilePictureSuccess(payload) {
    return {
        type: ON_STORE_PROFILE_PICTURE_SUCCESS,
        payload
    };
}

export function onStoreProfilePictureFail(error) {
    return {
        type: ON_STORE_PROFILE_PICTURE_FAIL,
        payload: error
    };
}

export function onStoreProfilePictureReset() {
    return {
        type: ON_STORE_PROFILE_PICTURE_RESET
    };
}

export const onSaveUserProfileDetails = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { initialData } = state.reducer;
        const pictureData = initialData.userProfile.data.profileImage;
        const userId = initialData.userProfile.data.id;
        const pictureUrlToUpdate = `users/${userId}/picture/profile.jpg`;
        dispatch(onStoreProfilePictureStart());

        try {
            API.get(
                process.env.REACT_APP_API_ENDPOINT_NAME,
                `/user/picture-upload?contentType=${pictureData.type}`
            )
                .then((signedURLResponse) => {
                    fetch(signedURLResponse, {
                        method: 'PUT',
                        body: pictureData,
                        headers: {
                            'Content-Type': pictureData.type
                        }
                    })
                        .then((response) => {
                            dispatch(onStoreProfilePictureSuccess(response));
                            dispatch(
                                storeProfileDetails({
                                    picture: pictureUrlToUpdate
                                })
                            );
                        })
                        .catch((error) => {
                            dispatch(onStoreProfilePictureFail(error));
                            dispatch(storeProfileDetails());
                        });
                })
                .catch((signedURLError) => {
                    dispatch(onStoreProfilePictureFail(signedURLError));
                    dispatch(storeProfileDetails());
                });
        } catch (error) {
            dispatch(onStoreProfilePictureFail(error));
            dispatch(storeProfileDetails());
        }
    };
};

export function onStoreProfileStartAction() {
    return {
        type: ON_STORE_PROFILE_START
    };
}

export function onStoreProfileSuccessAction(payload) {
    return {
        type: ON_STORE_PROFILE_SUCCESS,
        payload
    };
}

export function onStoreProfileFailAction(error) {
    return {
        type: ON_STORE_PROFILE_FAIL,
        payload: error
    };
}

export function onStoreProfileResetAction() {
    return {
        type: ON_STORE_PROFILE_RESET
    };
}

export const storeProfileDetails = (updatedUserData = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const { initialData } = state.reducer;
        dispatch(onStoreProfileStartAction());

        const data = {
            title: initialData.userProfile.data.title,
            credentials: initialData.userProfile.data.credentials,
            first_name: initialData.userProfile.data.first_name,
            last_name: initialData.userProfile.data.last_name,
            occupation: initialData.userProfile.data.occupation,
            linkedin_url: initialData.userProfile.data.linkedin_url,
            twitter_url: initialData.userProfile.data.twitter_url,
            fb_url: initialData.userProfile.data.fb_url,
            website_url: initialData.userProfile.data.website_url,
            about_me: initialData.userProfile.data.about_me,
            ...updatedUserData
        };

        return API.post(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/info', {
            body: data
        })
            .then((response) => {
                dispatch(onStoreProfileSuccessAction(response));
            })
            .catch((error) => {
                dispatch(onStoreProfileFailAction(error));
            });
    };
};

export function getPresentationDataStart() {
    return {
        type: GET_PRESENTATION_DATA_START
    };
}

export function getPresentationDataSuccess(payload) {
    return {
        type: GET_PRESENTATION_DATA_SUCCESS,
        payload
    };
}

export function getPresentationDataFail(error) {
    return {
        type: GET_PRESENTATION_DATA_FAIL,
        payload: error
    };
}

export function updatePresentationsInfoStart() {
    return {
        type: UPDATE_PRESENTATION_INFO_START
    };
}

export function updatePresentationsInfoSuccess(payload) {
    return {
        type: UPDATE_PRESENTATION_INFO_SUCCESS,
        payload
    };
}

export function updatePresentationsInfoFail(error) {
    return {
        type: UPDATE_PRESENTATION_INFO_FAIL,
        payload: error
    };
}

export const updatePresentationInfoSend = (presentationId, thumbFile) => {
    return (dispatch, getState) => {
        dispatch(updatePresentationsInfoStart());
        const state = getState();
        const { initialData } = state.reducer;
        const formData = {
            description: initialData.description,
            title: initialData.videoTitle
        };

        if (thumbFile) {
            formData.thumbnail = true;
        }

        return API.post(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            `/presentation/${presentationId}/info`,
            { body: formData }
        )
            .then((response) => {
                if (thumbFile) {
                    if (response.thumbnailSignedURL) {
                        const data = response.thumbnailSignedURL;
                        const thumbData = new FormData();
                        thumbData.append('Content-Type', thumbFile.type);
                        Object.entries(data.fields).forEach(([k, v]) => {
                            thumbData.append(k, v);
                        });
                        thumbData.append('file', thumbFile);

                        axios({
                            method: 'post',
                            url: data.url,
                            data: thumbData
                        })
                            .then((response) => {
                                dispatch(
                                    updatePresentationsInfoSuccess(response)
                                );
                            })
                            .catch((error) => {
                                dispatch(updatePresentationsInfoFail());
                            });
                    }
                } else {
                    dispatch(updatePresentationsInfoSuccess(response));
                }
            })
            .catch((error) => {
                dispatch(updatePresentationsInfoFail());
            });
    };
};

export const requestPresentationData = (presentationId) => {
    return (dispatch, getState) => {
        dispatch(getPresentationDataStart());

        return API.get(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            `/presentation/${presentationId}`
        )
            .then((response) => {
                dispatch(presentationData(response));
                dispatch(
                    getPresentationDataSuccess({
                        data: response,
                        presentationId
                    })
                );
            })
            .catch((error) => {
                dispatch(getPresentationDataFail(error));
            });
    };
};

export const removePresentationStart = () => {
    return {
        type: REMOVE_PRESENTATION_START
    };
};

export const removePresentationSuccess = (payload) => {
    return {
        type: REMOVE_PRESENTATION_SUCCESS,
        payload
    };
};

export const removePresentationFail = (error) => {
    return {
        type: REMOVE_PRESENTATION_FAIL,
        payload: error
    };
};

export const removePresentationReset = () => {
    return {
        type: REMOVE_PRESENTATION_RESET
    };
};

export const removePresentationAction = (id) => {
    return (dispatch, getState) => {
        dispatch(removePresentationStart());

        return API.post(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            `/presentation/${id}/remove`
        )
            .then((response) => {
                dispatch(removePresentationSuccess(response));
            })
            .catch((error) => {
                dispatch(removePresentationFail(error));
            });
    };
};

export const onPresentationInfoReset = () => {
    return {
        type: ON_PRESENTATION_INFO_RESET
    };
};

export function onPresentationTabHandleChange(id, value) {
    return {
        type: PRESENTATION_TAB_HANDLE,
        payload: {
            id,
            value
        }
    };
}

export function onPresentationFileSelectorErrorAction(type, value) {
    return {
        type: ON_PRESENTATION_FILE_SELECTOR_ERROR,
        payload: {
            type,
            value
        }
    };
}

export function onPresentationFileSelectorResetAction() {
    return {
        type: ON_PRESENTATION_FILE_SELECTOR_RESET
    };
}
