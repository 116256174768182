import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { onPresentationReset } from '../redux/action/Index';
import '../style/components/HomePageContent.scss';

const HomePage = (props) => {
    const { initialData } = props.state;
    const { presenterHomePage } = props.ApplicationText;
    const dispatch = useDispatch();

    const resetPresentationData = () => {
        dispatch(onPresentationReset());
    };

    return (
        <div className="homePageContent">
            <div className="homePageContent__banner">
                <div className="homePageContent__banner__left">
                    <p className="homePageContent__banner__left__title">
                        {presenterHomePage.text1}
                    </p>
                    <img
                        alt="banner"
                        className="homePageContent__banner__imgmobile"
                        src={initialData.assets.videoDemo}></img>
                    <p className="homePageContent__banner__left__desc">
                        {presenterHomePage.text2}
                    </p>
                    <Link to="/presentation-upload/">
                        <Button
                            id={'CreatePresentationButton'}
                            className="mainButton"
                            onClick={() => resetPresentationData()}>
                            <p>{presenterHomePage.btnText}</p>
                        </Button>
                    </Link>
                </div>
                <div className="homePageContent__banner__right">
                    <img alt="banner" src={initialData.assets.videoDemo}></img>
                </div>
            </div>

            {/*<div className="HomePageContent__chart">
                <div className="HomePageContent__chart__left">
                <h1>{presenterHomePage.dataChartTitle}</h1>
                <ResponsiveContainer width="100%" height={400} >
                    <BarChart data={initialData.DataCharts} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" stackId="a" fill="#01539D" />
                    <Bar dataKey="uv" stackId="a" fill="#F9B041" />
                    </BarChart>
                </ResponsiveContainer>
                </div>
                <div className="HomePageContent__chart__right">
                <h1>{presenterHomePage.dataChart2Title}</h1>
                <StackedBarChart height={400} data={initialData.StackedBarChartHomePageData} />
                </div>
            </div>*/}

            <div className="HomePageContent__card">
                {initialData.HomePageInfoCard.map((data, i) => {
                    return (
                        <div key={i} className="HomePageContent__card__item">
                            <img alt="card" src={data.icon} />
                            <h1>{data.title}</h1>
                            <p>{data.desc}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
