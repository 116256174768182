import { combineReducers } from 'redux';
import reducer from './ExperimentReducer';
import ApplicationText from './ApplicationText';
import presentationsReducer from 'redux/reducers/presentations/presentations';

const CombineReducers = combineReducers({
    reducer,
    presentations: presentationsReducer,
    ApplicationText
});

export default CombineReducers;
