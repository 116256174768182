import React from 'react';
import { BsFillPauseFill } from 'react-icons/bs';
import { BsFillPlayFill } from 'react-icons/bs';
import { BsStopFill } from 'react-icons/bs';
import styles from './RecorderActions.module.css';

const activeColor = '#283E59';
const inActiveColor = '#828282';
const playerActionStyles = {
    cursor: 'pointer'
};

const RecorderActions = (props) => {
    const { pauseResume, disableRecordAgain, cameraAccess, streaming } = props;
    const {
        uploading,
        disableRecording,
        isInternetOnline,
        hasVideoUploadError
    } = props;
    const { onOpenDialogHandler, startRecord, stopRecord, pauseResumeRecord } =
        props;
    const { isVideoReadyForStreaming } = props;

    let bottomContainerUI = null;

    if (!cameraAccess) {
        bottomContainerUI = <p>Tip: Click red camera to give camera access</p>;
    }

    if (cameraAccess && !streaming && !uploading && !disableRecording) {
        bottomContainerUI = (
            <button
                disabled={!isVideoReadyForStreaming}
                className="recordingpage__buttonStart"
                onClick={startRecord}>
                Start Recording
            </button>
        );
    }

    if (uploading) {
        bottomContainerUI = (
            <div className={styles.UploadingVideoActions}>
                {!isInternetOnline || hasVideoUploadError ? (
                    <button
                        disabled={!isInternetOnline || !hasVideoUploadError}
                        className={`recordingpage__buttonStart recordingpage__buttonRetryAgain`}
                        onClick={stopRecord}>
                        Retry Upload
                    </button>
                ) : (
                    <button
                        disabled={disableRecordAgain}
                        className={`recordingpage__buttonStart recordingpage__buttonRecordAgain`}
                        onClick={onOpenDialogHandler}>
                        Record Again
                    </button>
                )}
            </div>
        );
    }

    if (streaming) {
        bottomContainerUI = (
            <div className="recordingpage__recordingicon">
                <BsFillPlayFill
                    size={'2.5em'}
                    style={playerActionStyles}
                    color={(pauseResume && isVideoReadyForStreaming) ? activeColor : inActiveColor}
                    onClick={() => {
                        if (pauseResume && isVideoReadyForStreaming) {
                            pauseResumeRecord();
                        }
                    }}
                />
                <BsFillPauseFill
                    size={'2.5em'}
                    style={playerActionStyles}
                    color={!pauseResume ? activeColor : inActiveColor}
                    onClick={() => {
                        if (!pauseResume) {
                            pauseResumeRecord();
                        }
                    }}
                />
                <BsStopFill
                    size={'2.5em'}
                    style={playerActionStyles}
                    color={!pauseResume ? activeColor : inActiveColor}
                    onClick={() => {
                        if (!pauseResume) {
                            stopRecord();
                        }
                    }}
                />
            </div>
        );
    }

    return bottomContainerUI;
};

export default RecorderActions;
