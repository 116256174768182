import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './ReviewsTab.module.scss';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { getPresentations } from 'redux/action/Index';
import { getPresentationReviews } from 'redux/action/Index';
import PresentationsDatagrid from './PresentationsDatagrid';
import ReviewsDatagrid from './ReviewsDatagrid';
import { useHistory, useLocation } from 'react-router';
import { getQueryParam } from 'utils/router';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-footer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-columnHeader': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: '#283E59',
            fontWeight: 300,
            fontSize: 12,
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center',
            outline: 'none !important'
        },
        '& .MuiDataGrid-cell--withRenderer': {
            justifyContent: 'center !important',
            outline: 'none !important'
        }
    },
    inputSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        },
        color: '#283E59',
        fontSize: '18px',
        fontWeight: 600
    }
}));

const Reviews = (props) => {

    const [isReviewsDatagridActive, setIsReviewsDatagridActive] = useState(false);
    const [isOnReviewDetails, setIsOnReviewDetails] = useState(false);

    const presentationsState = useSelector(
        (state) => state.reducer.initialData.presentationsData
    );
    const presentationReviewsState = useSelector(
        (state) => state.presentations.presentationReviews
    );
    const dispatch = useDispatch();
    const history = useHistory();
    let location = useLocation();

    useEffect(() => {
        dispatch(getPresentations());

        const presentationId = getQueryParam('presentationId', location.search);
        if (!_.isEmpty(presentationId)) {
            onViewPresentationReviewsHandler(presentationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewsReportDurations = [
        { label: 'All Time', value: 'allTime' }
    ];

    const classes = useStyles();

    const onViewPresentationReviewsHandler = (presentationId) => {
        dispatch(getPresentationReviews(presentationId));
        setIsReviewsDatagridActive(true);
        history.push({
            pathname: '/presentations/dashboard/reviews',
            search:
                '?' +
                new URLSearchParams({ presentationId }).toString()
        });
    }

    const OnReviewDetailsPageLoadedHandler = (isOnReviewDetails) => {
        setIsOnReviewDetails(isOnReviewDetails);
    }

    const onBackToPresentationListHandler = () => {
        setIsReviewsDatagridActive(false);
        history.push({
            pathname: '/presentations/dashboard/reviews',
            search:
                '?' +
                new URLSearchParams().toString()
        });
    }

    return (
        <Grid container direction="row" justify="center">

            <Grid item lg={12}>
                <p className={styles.Title}>Reviews</p>
            </Grid>

            <Grid item lg={12} className={`${styles.ReviewsContainer}`}>
                <Grid container direction="row" justify="space-between">

                    <Grid item lg={8}>
                    </Grid>

                    <Grid item lg={4} style={{ textAlign: 'end' }}>
                        {!isOnReviewDetails ? <FormControl variant="outlined" style={{ width: '50%' }}>
                            <Select
                                native
                                // value={state.age}
                                // onChange={handleChange}
                                className={classes.inputSelect}
                                inputProps={{
                                    name: 'viewsReportDurations',
                                    id: 'outlined-age-native-simple'
                                }}
                                style={{
                                    color: '#283E59',
                                    fontSize: '18px',
                                    fontWeight: 600
                                }}>

                                {viewsReportDurations.map((viewsReportDuration, index) => (
                                    <option className={styles.ReportsSelect}
                                        key={index} value={viewsReportDuration.value}>{viewsReportDuration.label}</option>
                                ))}
                            </Select>
                        </FormControl> : null}

                    </Grid>

                    <Grid item lg={12} style={{ marginTop: 10 }}>
                        <div style={{ height: 560, width: '100%' }} className={classes.root}>
                            {!isReviewsDatagridActive ? <PresentationsDatagrid {...props}
                                classes={classes}
                                presentationsState={presentationsState}
                                onViewPresentationReviewsHandler={onViewPresentationReviewsHandler} /> :
                                <ReviewsDatagrid
                                    {...props}
                                    classes={classes}
                                    presentationReviewsState={presentationReviewsState}
                                    OnReviewDetailsPageLoadedHandler={OnReviewDetailsPageLoadedHandler}
                                    onBackToPresentationListHandler={onBackToPresentationListHandler}
                                />}
                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </Grid >
    );
}

export default Reviews;
