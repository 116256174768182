import React from 'react';
import { Button } from 'react-bootstrap';

const ButtonComp = (props) => {

    let classes = [
        props.className
    ];

    if (!props.noCustomClass) {
        classes.push("CustomClass");
    }

    if (props.btnDisable) {
        classes.push("btn-disable");
    }

    return (
        <span>
            <Button disabled={props.btnDisable} onClick={props.handleClick}
                style={{ ...props.style }}
                className={[...classes]}
                variant={props.varient || 'primary'}
                id={props.id}
            >{props.name}
            </Button>
        </span >
    );
}

export default ButtonComp;