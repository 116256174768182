import React, { useState, useEffect } from 'react';
import LogRocket from 'logrocket';
import { Fragment } from 'react';
import AppBar from 'components/AppBar';
// import FileUploadPanel from 'components/FileUploadPanel/FileUploadPanel';
import MediaRecordingOption from 'components/MediaRecordingOption/MediaRecordingOption';
import RecordingPage from 'components/RecordingPage';
import PresentationPurpose from 'components/PresentationPurpose';
import FooterComp from 'components/FooterComp/FooterComp';
import CategoryPricePage from 'components/CategoryPricePage/CategoryPricePage';
import AdditionalReferenceFilesUpload from 'components/AdditionalReferenceFilesUpload/AdditionalReferenceFilesUpload';
import { PresentationUpload } from 'components/PresentationUpload';
import { ReplayPresentationVideo } from 'components/ReplayPresentationVideo';
import VisualPresentations from 'components/VisualPresentations';
import UserProfile from 'components/UserProfile';
import HomePage from './HomePage';
import PresentationPanel from './PresentationPanel';
import EditPresentation from 'components/EditPresentation/EditPresentation';
import NewCourse from 'components/NewCourse/NewCourse';
import Dashboard from 'components/Dashboard';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import LoaderComp from 'components/LoaderComp/LoaderComp';
import { CenterWrapper } from 'components/Shared/HOC';
import awsconfig from '../aws-exports';
import { Switch, Route, Redirect } from 'react-router-dom';
import { WithScrollTop } from 'components/Shared/HOC';
import { atom, useAtom } from 'jotai';

export const zoneInfoAtom = atom(null);

function PrivateRoute({ component: ChildComponent, ...rest }) {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [zoneInfo, setZoneInfo] = useAtom(zoneInfoAtom);

    useEffect(() => {
        const fetchData = async () => {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-unused-vars
                const userAuthResult = await Auth.signIn(
                    awsconfig.dev_auth_user,
                    awsconfig.dev_auth_pwd
                );
            }
            try {
                // eslint-disable-next-line no-unused-vars
                const session = await Auth.currentSession();
                const result = await Auth.currentAuthenticatedUser({
                    bypassCache: false
                });
                axios.defaults.headers.common['accesstoken'] =
                    result.signInUserSession.accessToken.jwtToken;
                //TODO store in storage later
                axios.defaults.headers.common['userid'] = result.attributes.sub;

                if (result.attributes.zoneinfo) {
                    const userGroup =
                        result.signInUserSession.accessToken.payload[
                            'cognito:groups'
                        ];
                    const checkAdminGroup = `$${result.attributes.zoneinfo}_admins`;
                    const checkGroupName = `$${result.attributes.zoneinfo}_presenters`;


                    const found = userGroup.find(
                        (item) =>
                            item.includes(checkAdminGroup) ||
                            item.includes(checkGroupName)
                    );

                    if (found) {
                        setZoneInfo(result.attributes.zoneinfo);
                        LogRocket.identify(result.attributes.sub);
                        setIsAuthenticated(result);
                        setLoading(false);
                    } else {
                        setIsAuthenticated(false);
                        setLoading(false);
                    }
                } else {
                    LogRocket.identify(result.attributes.sub);
                    setIsAuthenticated(result);
                    setLoading(false);
                }
            } catch (err) {
                setIsAuthenticated(false);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <Route
            {...rest}
            render={({ location, ...props }) =>
                isAuthenticated ? (
                    <ChildComponent {...props} {...rest} />
                ) : loading ? (
                    <CenterWrapper>
                        <LoaderComp />
                    </CenterWrapper>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const Container = (props) => {
    return (
        <Fragment>
            <AppBar />
            <div className="App-intro">
                <WithScrollTop>
                    <Switch>
                        <PrivateRoute exact path="/" component={HomePage} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        {/* <PrivateRoute
                            path="/upload"
                            component={FileUploadPanel}
                        /> */}
                        {/* New /upload */}
                        <PrivateRoute
                            path="/new-course"
                            component={NewCourse}
                        />
                        <PrivateRoute
                            path="/presentation-upload"
                            component={PresentationUpload}
                        />
                        <PrivateRoute
                            path="/presentation-replay"
                            component={ReplayPresentationVideo}
                        />
                        <PrivateRoute
                            path="/media-option"
                            component={MediaRecordingOption}
                        />
                        <PrivateRoute
                            path="/presentation-purpose"
                            component={PresentationPurpose}
                        />
                        <PrivateRoute
                            path="/recordingpage"
                            component={RecordingPage}
                        />
                        <PrivateRoute
                            path="/presentation-category"
                            component={CategoryPricePage}
                        />
                        <PrivateRoute
                            path="/presentation/view/:presentationId"
                            component={VisualPresentations}
                        />
                        <PrivateRoute
                            path="/presentation/upload-reference-files"
                            component={AdditionalReferenceFilesUpload}
                        />
                        <PrivateRoute
                            path="/presentation/edit/:id"
                            component={EditPresentation}
                        />
                        <PrivateRoute
                            path="/user/profile"
                            component={UserProfile}
                        />
                        <PrivateRoute
                            path="/presentations/dashboard/:id"
                            component={PresentationPanel}
                        />
                        <Route
                            path="/login"
                            render={() =>
                                (window.location =
                                    process.env.REACT_APP_ROOT_URL + '/login')
                            }
                        />
                    </Switch>
                </WithScrollTop>
            </div>
            <FooterComp {...props} />
        </Fragment>
    );
};

export default Container;
