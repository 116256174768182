import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import styles from './NewCourse.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from 'components/LoaderComp/LoaderComp';
import Button from 'components/Shared/UI/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { onPresentationInfoReset } from 'redux/action/Index';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getPresentations } from 'redux/action/Index';

const classes = {
    root: {
        color: '#F9B041',
        border: '1px solid #F9B041',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '16px',
        paddingBottom: '16px',
        width: '75%',
        display: 'block',
        margin: '0px auto',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px -2px rgba(0, 0, 0, 16%)',
        borderRadius: '5px',
        fontSize: 'clamp(0.875rem, 0.85vw, 1rem)',
        fontWeight: 600
    },
    actionButtonSave: {
        color: '#FFFFFF',
        background: '#F9B041',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        width: '20%'
    },
    actionButtonCancel: {
        color: '#FFFFFF',
        background: '#808080',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        width: '20%',
        marginRight: '40px'
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: '#283E59',
        fontSize: '1rem',
        fontWeight: '600',
        fontStyle: 'normal'
    }
}));

const ModuleSelect = (props) => {
    return (
        <Grid item lg={12}>
            <div className={`${styles.DescriptionSection}`}>
                <FormControl
                    style={{ minWidth: '100%' }}
                    variant="outlined"
                    className={classes.formControl}>
                    <span className={styles.Title}>{props.text}:</span>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue=""
                        name={`${props.moduleIndex}`}
                        onChange={props.handle}>
                        {props.data.map((pres) => {
                            if (pres.id) {
                                return (
                                    <MenuItem key={pres.id} value={pres}>
                                        {pres.createdDate.slice(0, 10)} :{' '}
                                        {pres.title}
                                    </MenuItem>
                                );
                            }
                        })}
                    </Select>
                </FormControl>
            </div>
        </Grid>
    );
};

const NewCourse = (props) => {
    const materialStyles = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const presentationsState = useSelector(
        (state) => state.reducer.initialData.presentationsData
    );

    useEffect(() => {
        dispatch(getPresentations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        data: presentationData,
        loading
        // error,
        // success
    } = presentationsState;

    const [modules, setModules] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [references, setReferences] = React.useState({});
    const moduleCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const handleChange = (event) => {
        setModules((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value.id
        }));
        setReferences((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value.referenceFiles
        }));
    };

    //const [presentationData, setPresentationData] = useState(null);
    const { classes } = props;

    let containerUI = <LoaderComponent />;

    const createCourse = async () => {
        const reducedReferences = Object.values(references)
            .reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
            )
            .filter((item) => item);

        const postBody = {
            title: title,
            description: description,
            modules: modules
        };

        if (reducedReferences && reducedReferences.length > 0) {
            postBody.referenceFiles = reducedReferences;
        }

        containerUI = <LoaderComponent />;

        await API.post(process.env.REACT_APP_API_ENDPOINT_NAME, `/course/new`, {
            body: postBody
        });

        history.push('/presentations/dashboard/presentations');
    };

    if (!loading && presentationData) {
        containerUI = (
            <Grid container direction="row" justify="center">
                <Grid item xl={12} lg={10}>
                    <p className={styles.Title}>New Course details</p>
                </Grid>

                <Grid item xl={12} lg={10} className={`${styles.Container}`}>
                    <Grid container direction="row" justify="space-between">
                        <Grid item lg={12}>
                            <div className={`${styles.TitleSection}`}>
                                <span className={styles.Title}>Title:</span>
                                <span className={styles.ToolTip}>
                                    Click inside the box to edit
                                </span>
                                <div className={`${styles.TitleSectionInput}`}>
                                    <Box width="100%">
                                        <TextField
                                            classes={{
                                                root: materialStyles.root
                                            }}
                                            id="outlined-full-width"
                                            placeholder="Please type here..."
                                            multiline
                                            InputProps={{
                                                className: materialStyles.input
                                            }}
                                            style={{ width: '100%' }}
                                            value={title}
                                            onChange={(event) => {
                                                setTitle(event.target.value);
                                            }}
                                            rows={2}
                                            variant="outlined"
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={12}>
                            <div className={`${styles.DescriptionSection}`}>
                                <span className={styles.Title}>
                                    Description:
                                </span>
                                <span className={styles.ToolTip}>
                                    Click inside the box to edit
                                </span>
                                <div
                                    className={`${styles.DescriptionSectionInput}`}>
                                    <Box width="100%">
                                        <TextField
                                            classes={{
                                                root: materialStyles.root
                                            }}
                                            id="outlined-full-width"
                                            placeholder="Please type here..."
                                            multiline
                                            InputProps={{
                                                className: materialStyles.input
                                            }}
                                            style={{ width: '100%' }}
                                            value={description}
                                            onChange={(event) =>
                                                setDescription(
                                                    event.target.value
                                                )
                                            }
                                            rows={3}
                                            variant="outlined"
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Grid>

                        {moduleCount.map((moduleIndex) => {
                            return (
                                <ModuleSelect
                                    key={moduleIndex}
                                    text={`Module ${moduleIndex}`}
                                    handle={handleChange}
                                    data={presentationData}
                                    moduleIndex={moduleIndex}
                                />
                            );
                        })}

                        <Grid item lg={12}>
                            <div className={`${styles.ActionSection}`}>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtonCancel}
                                    onClick={() => {
                                        dispatch(onPresentationInfoReset());
                                        history.push(
                                            '/presentations/dashboard/presentations'
                                        );
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtonSave}
                                    onClick={createCourse}
                                    disabled={
                                        !title ||
                                        !description ||
                                        !modules['1'] ||
                                        !modules['2']
                                    }>
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Container>
                <Box>{containerUI}</Box>
            </Container>
        </>
    );
};

export default withStyles(classes)(NewCourse);
