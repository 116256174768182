import React from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import styles from './ConfirmationDialog.module.css';

const ConfirmationDialog = (props) => {
    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={props.onCloseDialogAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <p className={styles.Message}>{props.dialogMessage}</p>
                <div className="dialog__button">
                    <Button
                        className="no"
                        style={{ marginRight: '23px' }}
                        onClick={props.onCloseDialogAction}>
                        No
                    </Button>
                    <Button
                        className="yes"
                        onClick={props.onConfirmDialogAction}>
                        Yes
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmationDialog;
