export const getFileExtension = (baseName) => {
    const pos = baseName.lastIndexOf('.');
    return baseName.slice(pos + 1);
};

export const acceptedTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/x-iwork-keynote-sffkey'
];

export const typeMap = {
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    ppt: 'application/mspowerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    key: 'application/x-iwork-keynote-sffkey'
};
