import { API } from 'aws-amplify';
import { onCategoryPageChange } from './Index';

export const onFindPresentationOwnerSet = (presentationId) => {
    return (dispatch, getState) => {
        return API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/info')
            .then((response) => {
                dispatch(
                    onCategoryPageChange(response.presentationOwnerSet || [])
                );
            })
            .catch((error) => {
                throw error;
            });
    };
};
