import * as actionTypes from '../../action/presentations/actionTypes';
import { updateObject } from 'utils/object';

const initialState = {
    presentationReviews: {
        loading: false,
        success: false,
        error: null,
        data: null
    }
};

const getPresentationReviewsStart = (state, action) => {
    const newState = updateObject(state, {
        presentationReviews: updateObject(state.presentationReviews, {
            loading: true,
            error: null,
            success: false,
            data: null
        })
    });
    return newState;
};

const getPresentationReviewsSuccess = (state, action) => {
    const newState = updateObject(state, {
        presentationReviews: updateObject(state.presentationReviews, {
            loading: false,
            error: null,
            success: true,
            data: action.payload
        })
    });
    return newState;
};

const getPresentationReviewsFail = (state, action) => {
    const newState = updateObject(state, {
        presentationReviews: updateObject(state.presentationReviews, {
            loading: false,
            error: action.payload.error,
            success: false,
            data: null
        })
    });
    return newState;
};

const getPresentationReviewsReset = (state, action) => {
    const newState = updateObject(state, {
        presentationReviews: updateObject(state.presentationReviews, {
            loading: false,
            success: false,
            error: null,
            data: null
        })
    });
    return newState;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRESENTATION_REVIEWS_START:
            return getPresentationReviewsStart(state, action);
        case actionTypes.GET_PRESENTATION_REVIEWS_SUCCESS:
            return getPresentationReviewsSuccess(state, action);
        case actionTypes.GET_PRESENTATION_REVIEWS_FAIL:
            return getPresentationReviewsFail(state, action);
        case actionTypes.GET_PRESENTATION_REVIEWS_RESET:
            return getPresentationReviewsReset(state, action);

        default:
            return state;
    }
};

export default reducer;
