import React from 'react';
import { useMediaQuery } from 'react-responsive';

const withResponsiveQuery = (Component) => (props) => {
    const mediaQuery = {
        isDesktopOrLaptopHD: false,
        isDesktopOrLaptopHDPlus: false,
        isDesktopOrLaptopHiDpi: false
    };

    mediaQuery.isDesktopOrLaptopHD = useMediaQuery({
        query: '(max-device-width: 1280px)'
    });

    mediaQuery.isDesktopOrLaptopHDPlus = useMediaQuery({
        query: '(max-device-width: 1366px)'
    });

    mediaQuery.isDesktopOrLaptopHiDpi = useMediaQuery({
        query: '(max-device-width: 1440px)'
    });
    return <Component {...props} mediaQuery={mediaQuery} />;
};

export default withResponsiveQuery;
