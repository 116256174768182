import React from 'react';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import styles from './EmbedVideo.module.css';
import ReactModalAdapter from 'components/Shared/Helpers/ReactModalAdapter';
import styled from 'styled-components';
import { useEffect } from 'react';

const StyleSharedModal = styled(ReactModalAdapter)`
    &.SharePresentationModal__overlay {
        transition: opacity 2000ms ease-in-out;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        inset: 0px;
        z-index: 50;
        --bg-opacity: 0.3;
        background-color: rgba(0, 0, 0, var(--bg-opacity));
        transition: opacity 2000ms ease-in-out 0s;
    }
    &.SharePresentationModal__content {
        max-width: 1280px;
        position: absolute;
        inset: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        align-items: flex-start;
        border-radius: 0.5rem;
        outline: transparent solid 2px;
        outline-offset: 2px;
        width: 33.3333%;
        max-height: 50%;
        margin: auto;
        --bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        place-self: flex-start start;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-bottom: 2.5rem;
        height: fit-content;
    }
    .content {
    }
`;

const EmbedVideo = (props) => {
    const [isShareModalActive, setIsShareModalActive] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const saveEmbedVideo = () => {
        if (
            inputValue.startsWith('https://player.vimeo.com') &&
            inputValue.indexOf('vimeo.com') > -1 &&
            inputValue.indexOf('?h=') > -1
        ) {
            //save
            props.uploadVideoEmbedHandler(inputValue);
            setIsShareModalActive(false);
        } else {
            setError(true);
            setErrorMsg(
                'Video link should contain player.vimeo.com and should have parameter ?h=<code>'
            );
        }
    };

    const closeShareModal = () => {
        setIsShareModalActive(false);
    };

    const shareModal = (
        <StyleSharedModal
            closeTimeoutMS={300}
            className={`SharePresentationModal`}
            isOpen={isShareModalActive}
            onRequestClose={() => {
                closeShareModal();
            }}
            shouldCloseOnOverlayClick={true}
            contentClass={styles.contentClass}
            overlayClass={styles.overlayClass}>
            <Grid container direction="row" justify="space-between">
                <Grid item lg={12} sm={12}>
                    <div className={`${styles.ShareModalHeaderAction}`}>
                        <h1 className={`${styles.ShareModalHeader}`}>
                            Embed Video
                        </h1>
                        <IoMdCloseCircleOutline
                            className="cursor-pointer"
                            size={'1.8em'}
                            color={'#283E59'}
                            onClick={() => {
                                setIsShareModalActive(false);
                            }}
                        />
                    </div>
                </Grid>

                <Grid item lg={12} sm={12}>
                    <input
                        value={inputValue || ''}
                        type="url"
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        className="mediaoption__input"
                        placeholder={
                            'https://player.vimeo.com/video/223345?h=0612g40567'
                        }
                    />
                    {error ? (
                        <>
                            <p className={styles.ErrorMessage}>{errorMsg}</p>
                        </>
                    ) : null}
                </Grid>

                <Grid item lg={12} sm={12}>
                    <div className={`${styles.InputSave}`}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveEmbedVideo}>
                            Save
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </StyleSharedModal>
    );

    return (
        <div>
            <React.Fragment>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setIsShareModalActive(true);
                    }}>
                    Embed Video
                </Button>
                {shareModal}
            </React.Fragment>
        </div>
    );
};

export default EmbedVideo;
