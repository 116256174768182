import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FileDrop } from 'react-file-drop';
import {
    onUploadPresentationReferenceFile,
    onUploadAllReferenceFilesResetAction,
    onUploadReferenceFileResetAction,
    onDiscardUploadedReferenceFilesAction,
    onRemoveFileFromReferenceFilesAction,
    handleUpload
} from 'redux/action/Index';
import { acceptedTypes, typeMap } from 'utils/files';
import FileChooser from './FileChooser/FileChooser';
import FilesUploadStatusWrapper from './FilesUploadStatusWrapper/FilesUploadStatusWrapper';
import Button from 'components/ButtonComp/ButtonComp';
import CustomizedDialogBox from 'components/CustomizedDialogBox/CustomizedDialogBox';
import Loader from 'components/LoaderComp/LoaderComp';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import styles from './AdditionalReferenceFilesUpload.module.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const AdditionalReferenceFilesUpload = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const referenceFileUploadingState = useSelector(
        (state) => state.reducer.initialData.referenceFileUploading
    );
    const referenceFilesState = useSelector(
        (state) => state.reducer.initialData.referenceFiles
    );
    const referenceFilesUploadingStatusState = useSelector(
        (state) => state.reducer.initialData.referenceFilesUploadingStatus
    );
    const userProfileState = useSelector(
        (state) => state.reducer.initialData.userProfile
    );
    const submitPresentationDataState = useSelector(
        (state) => state.reducer.initialData.submitPresentationData
    );
    const referenceFilesFailedToUploadState = useSelector(
        (state) => state.reducer.initialData.referenceFilesFailedToUpload
    );
    const initialDataState = useSelector((state) => state.reducer.initialData);
    const [referenceFilesToUpload, setReferenceFilesToUpload] = useState([]);
    const [maxFilesUploadLimitReached, setMaxFilesUploadLimitReached] =
        useState(false);

    const [isCelebrateDialogVisible, setIsCelebrateDialogVisible] =
        useState(false);
    const [submitPresentationLoading, setSubmitPresentationLoading] =
        useState(false);

    useEffect(() => {
        dispatch(onUploadReferenceFileResetAction());
        dispatch(onUploadAllReferenceFilesResetAction());
    }, []);

    useEffect(() => {
        if (submitPresentationDataState.loading) {
            setSubmitPresentationLoading(true);
        }

        if (!submitPresentationDataState.loading && submitPresentationLoading) {
            if (!isCelebrateDialogVisible) {
                history.push('/presentations/dashboard/presentations');
            } else {
                history.push('/user/profile');
            }
        }
    }, [submitPresentationDataState]);

    const onDropFiles = async (files, event) => {
        referenceFilesSelectionHandler(files);
    };

    const onFilesBrowserSelection = (files) => {
        referenceFilesSelectionHandler(files);
    };

    const getFileExtension = (baseName) => {
        const pos = baseName.lastIndexOf('.');
        return baseName.slice(pos + 1);
    };

    const checkNameOfTheFile = (newFileName) => {
        const counter = referenceFilesToUpload.filter(
            (f) => f.fileName === newFileName
        ).length;
        if (counter === 0) {
            return newFileName;
        }
        if (counter === 1) {
            const newName = `${newFileName.split('.')[0]}_${counter}.${
                newFileName.split('.')[1]
            }`;
            return checkNameOfTheFile(newName);
        }
    };

    const referenceFilesSelectionHandler = (files) => {
        if (referenceFilesUploadingStatusState.loading) {
            return;
        }

        if (
            referenceFilesToUpload.length >= 10 ||
            files.length + referenceFilesToUpload.length > 10
        ) {
            setMaxFilesUploadLimitReached(true);
            return;
        } else {
            setMaxFilesUploadLimitReached(false);
        }

        const localFilesList = [];

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const file = files[fileIndex];
            const fileDetails = {
                file: null,
                fileName: '',
                fileType: '',
                fileSize: '',
                error: {
                    maxFileSize: false,
                    unsupportedFileType: false,
                    failedToUploadFile: false
                },
                isFileReadyToUpload: false,
                progress: 0
            };

            const fileName = checkNameOfTheFile(file.name);
            let fileType = file.type;
            const fileSize = file.size;

            if (acceptedTypes.indexOf(fileType) === -1) {
                const fileExtension = getFileExtension(fileName);
                fileType = typeMap[fileExtension];
            }

            fileDetails.file = file;
            fileDetails.fileName = fileName;
            fileDetails.fileType = fileType;
            fileDetails.fileSize = fileSize;

            if (fileSize > 104857600) {
                fileDetails.error.maxFileSize = true;
            } else if (acceptedTypes.indexOf(fileType) !== -1) {
                fileDetails.isFileReadyToUpload = true;
            } else {
                fileDetails.error.unsupportedFileType = true;
            }

            localFilesList.push(fileDetails);
        }

        setReferenceFilesToUpload((state) => [...state, ...localFilesList]);

        const filesToUploadOnServer = localFilesList.filter(
            (file) => file.isFileReadyToUpload
        );
        dispatch(onUploadPresentationReferenceFile(filesToUploadOnServer));
    };

    const onDiscardUploadedReferenceFilesHandler = () => {
        dispatch(onUploadReferenceFileResetAction());
        dispatch(onDiscardUploadedReferenceFilesAction());
        setReferenceFilesToUpload([]);
        setMaxFilesUploadLimitReached(false);
    };

    const onNextPageHandler = () => {
        if (userProfileState.data.newPresenter) {
            setIsCelebrateDialogVisible(true);
        } else {
            dispatch(handleUpload('upload'));
        }
    };

    const onDiscardErrorFileHandler = (fileName) => {
        let updatedReferenceFilesToUpload = _.cloneDeep(referenceFilesToUpload);
        updatedReferenceFilesToUpload = updatedReferenceFilesToUpload.filter(
            (file) => file.fileName !== fileName
        );
        setReferenceFilesToUpload(updatedReferenceFilesToUpload);
        if (updatedReferenceFilesToUpload.length < 10) {
            setMaxFilesUploadLimitReached(false);
        }
    };

    const onRemoveUploadedFileHandler = (fileName) => {
        let updatedReferenceFilesToUpload = _.cloneDeep(referenceFilesToUpload);
        updatedReferenceFilesToUpload = updatedReferenceFilesToUpload.filter(
            (file) => file.fileName !== fileName
        );
        setReferenceFilesToUpload(updatedReferenceFilesToUpload);
        dispatch(onRemoveFileFromReferenceFilesAction(fileName));
        if (updatedReferenceFilesToUpload.length < 10) {
            setMaxFilesUploadLimitReached(false);
        }
    };

    const onCelebrateDialogHandleUpload = (arg) => {
        dispatch(handleUpload(arg));
    };

    let containerUI = (
        <Grid container alignItems="center" justify="center">
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 className={styles.MainHeading}>
                            Reference files upload
                        </h2>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={styles.Container}>
                            <Grid item xs={12}>
                                <div className={styles.SubHeadingContainer}>
                                    <h3 className={styles.SubHeading}>
                                        Upload your files here
                                    </h3>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <div className={styles.FileUploadContainer}>
                                    <FileDrop
                                        className={`file-drop ${styles.FileDrop}`}
                                        onDrop={(files, event) =>
                                            onDropFiles(files, event)
                                        }>
                                        <div
                                            className={
                                                styles.FileUploadImageContainer
                                            }>
                                            <img
                                                src={require('../../assets/images/additional-reference-files-upload/file-upload-icon.png')}
                                                alt=""
                                                className={
                                                    styles.FileUploadImage
                                                }
                                            />
                                        </div>

                                        {
                                            <>
                                                <p
                                                    className={
                                                        styles.DragAndDropMessage
                                                    }>
                                                    Drag & Drop File
                                                </p>
                                                <span className={styles.Or}>
                                                    or
                                                </span>
                                                <FileChooser
                                                    onFilesBrowserSelection={
                                                        onFilesBrowserSelection
                                                    }
                                                    referenceFilesUploadingStatusState={
                                                        referenceFilesUploadingStatusState
                                                    }
                                                />
                                                <span
                                                    className={
                                                        styles.SupportedFileTypeMessage
                                                    }>
                                                    Supported files: PDF,
                                                    PowerPoint, Word, Keynote
                                                </span>

                                                {maxFilesUploadLimitReached ? (
                                                    <span
                                                        className={
                                                            styles.MaxFilesUploadLimitReachedError
                                                        }>
                                                        Limit Reached: You
                                                        cannot upload more than
                                                        10 reference files
                                                    </span>
                                                ) : null}
                                            </>
                                        }
                                    </FileDrop>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FilesUploadStatusWrapper
                                    referenceFilesToUpload={
                                        referenceFilesToUpload
                                    }
                                    referenceFileUploadingState={
                                        referenceFileUploadingState
                                    }
                                    referenceFilesState={referenceFilesState}
                                    referenceFilesUploadingStatusState={
                                        referenceFilesUploadingStatusState
                                    }
                                    onDiscardUploadedReferenceFilesHandler={
                                        onDiscardUploadedReferenceFilesHandler
                                    }
                                    onDiscardErrorFileHandler={
                                        onDiscardErrorFileHandler
                                    }
                                    onRemoveUploadedFileHandler={
                                        onRemoveUploadedFileHandler
                                    }
                                    referenceFilesFailedToUploadState={
                                        referenceFilesFailedToUploadState
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={10}>
                <div className={styles.FooterContainer}>
                    {isCelebrateDialogVisible ? (
                        <CustomizedDialogBox
                            handleUpload={onCelebrateDialogHandleUpload}
                            state={{
                                initialData: initialDataState
                            }}
                        />
                    ) : null}
                    <Button
                        id={'ReferenceFilesUploadNextButtonAction'}
                        name={'Next'}
                        handleClick={() => onNextPageHandler()}
                        btnDisable={
                            referenceFilesState.length < 1 ||
                            !referenceFilesUploadingStatusState.success
                        }
                    />
                </div>
            </Grid>
        </Grid>
    );

    if (submitPresentationDataState.loading) {
        containerUI = <Loader />;
    }

    return <div className={classes.root}>{containerUI}</div>;
};

export default AdditionalReferenceFilesUpload;
