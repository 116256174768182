import React from 'react';
import styles from './LatestReports.module.scss';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ButtonComp from 'components/ButtonComp/ButtonComp';
import Loader from 'components/LoaderComp/LoaderComp';
import { P } from 'components/Shared/Theme';

const LatestReports = (props) => {
    const { presentationReportLast30Days, topPresentationsLast30Days } = props;
    const { data, loading } = props;

    let containerUI = (
        <div
            className={`${styles.ReportsSubContainer} ${styles.LoaderContainer}`}>
            <Loader />
        </div>
    );

    if (!loading) {
        containerUI = (
            <Grid
                container
                direction="row"
                justify="center"
                className={`${styles.ReportsSubContainer}`}>
                <Grid
                    item
                    lg={12}
                    className={`${styles.PaddingContainerGrid} ${styles.CommonContainerHead}`}>
                    <P inline="true" className={styles.ContainerTitle}>
                        Reports
                    </P>
                    <P inline="true" className={styles.ContainerSubTitle}>
                        Last 30 days
                    </P>

                    <ul style={{ padding: 0 }}>
                        {data
                            ? presentationReportLast30Days.map(
                                  (report, index) => (
                                      <li
                                          key={index}
                                          className={
                                              styles.LatestPresentationDetails
                                          }>
                                          <P inline="true">{report.label}</P>
                                          <P inline="true">
                                              {report.viewCount
                                                  ? report.viewCount
                                                  : '-'}
                                          </P>
                                      </li>
                                  )
                              )
                            : null}
                    </ul>

                    <P inline="true" className={`${styles.ContainerTitle}`}>
                        Top Modules
                    </P>
                    <P inline="true" className={styles.ContainerSubTitle}>
                        Last 30 days - views
                    </P>

                    <ul style={{ padding: 0 }}>
                        {topPresentationsLast30Days.map((report, index) => (
                            <li
                                key={index}
                                className={styles.LatestPresentationDetails}>
                                <P inline="true">{report.label}</P>
                                <P inline="true">{report.views}</P>
                            </li>
                        ))}
                    </ul>
                </Grid>

                <Grid item lg={12} className={styles.ReportsSubButtonContainer}>
                    <NavLink
                        to="/presentations/dashboard/reports"
                        activeClassName={styles.ActiveNavLink}>
                        <ButtonComp
                            name={'View Report'}
                            style={{
                                marginTop: 0,
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingRight: 32,
                                paddingLeft: 32
                            }}
                            className={styles.Button}
                        />
                    </NavLink>
                </Grid>
            </Grid>
        );
    }

    return containerUI;
};

export default LatestReports;
