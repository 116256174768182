import React, { Fragment, useState } from 'react';
import Box from '@material-ui/core/Box';
import { InputAdornment } from '@material-ui/core';
import ButtonComp from '../../ButtonComp/ButtonComp';
import TextField from '@material-ui/core/TextField';
import styles from './ListValues.module.scss';
import { useDispatch } from 'react-redux';

const ListValues = (props) => {

    const { classes } = props;
    const [resultValues, setResultValues] = useState('');
    const [isEditingIndex, setIsEditingIndex] = useState(null);
    const [isEditingValue, setIsEditingValue] = useState(null);
    const dispatch = useDispatch();

    const { data } = props;
    const { onChange } = props;

    let stateValuesList = null;

    if (data) {

        stateValuesList = data.map((learningObjective, index) => {

            const commonButtonActionClasses = [];
            let isSaveDisabled = false;
            if (!isEditingValue || isEditingValue.trim() === '') {
                commonButtonActionClasses.push(styles.Disabled);
                isSaveDisabled = true;
            }

            let isInEditingMode = false;
            let actionUI = (
                <>
                    <i className={`fa fa-pencil ${styles.P5} fa-lg ${styles.IconEdit}`} aria-hidden="true"
                        onClick={() => onStartEditResultValue(index)}></i>
                    <i className={`fa fa-times-circle-o fa-lg ${styles.P5} ${styles.IconCross}`} aria-hidden="true"
                        onClick={() => onDeleteResultValue(index)}></i>
                </>
            )

            if (index === isEditingIndex) {
                isInEditingMode = true;
                actionUI = (
                    <>
                        <i className={`fa fa-check fa-lg ${styles.P5} ${styles.IconEdit} ${commonButtonActionClasses.join(' ')}`} aria-hidden="true" onClick={() => {
                            if (!isSaveDisabled) {
                                onFinishEditResultValue();
                            }
                        }}></i>
                        <i className={`fa fa-ban fa-lg ${styles.P5} ${styles.IconCross}`} aria-hidden="true" onClick={() => onCancelEditResultValue()}></i>
                    </>
                )
            } else if (isEditingIndex !== null) {
                actionUI = null;
            }

            return (
                <li key={index}>
                    <Box width="100%" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                        {!isInEditingMode ? <span className={styles.ViewObjective}>{learningObjective}</span> :
                            (<TextField
                                id="outlined-full-width"
                                margin="normal"
                                fullWidth
                                classes={{
                                    root: classes.root
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className={styles.ViewObjective}
                                value={isEditingValue || ''}
                                onChange={(event) => onChangeEditResultValue(event.target.value)}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 999
                                }}
                                InputProps={{
                                    className: classes.input
                                }}
                            />)
                        }
                        <span>
                            {actionUI}
                        </span>
                    </Box >
                </li >
            )
        });
    }

    const onChangeNewValue = (value) => {
        setResultValues(value);
    }

    const onAddResultValue = () => {
        const updatedResultValues = [...data, resultValues.trim()];
        dispatch(onChange(updatedResultValues));
        setResultValues('');
    }

    const onDeleteResultValue = (index) => {
        const updatedResultValues = [...data];
        updatedResultValues.splice(index, 1);
        dispatch(onChange(updatedResultValues));
    }

    const onStartEditResultValue = (index) => {
        const updatedResultValues = [...data];
        setIsEditingIndex(index);
        setIsEditingValue(updatedResultValues[index]);
    }

    const onChangeEditResultValue = (value) => {
        setIsEditingValue(value);
    }

    const onFinishEditResultValue = () => {
        const updatedResultValues = [...data];
        updatedResultValues[isEditingIndex] = isEditingValue.trim();
        dispatch(onChange(updatedResultValues));
        setIsEditingIndex(null);
        setIsEditingValue(null);
    }

    const onCancelEditResultValue = () => {
        setIsEditingIndex(null);
        setIsEditingValue(null);
    }

    return (
        <Fragment>
            <TextField
                id="outlined-full-width"
                placeholder="Please type here..."
                fullWidth
                margin="normal"
                classes={{
                    root: classes.root
                }}
                InputLabelProps={{
                    shrink: true
                }}
                value={resultValues || ''}
                onChange={(event) => onChangeNewValue(event.target.value)}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                InputProps={{
                    className: classes.input,
                    endAdornment: (
                        <InputAdornment position='end'>
                            <ButtonComp
                                name={'Add'}
                                style={{
                                    marginTop: 0,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    paddingRight: 32,
                                    paddingLeft: 32
                                }}
                                btnDisable={!resultValues || resultValues.trim() === '' || isEditingIndex}
                                handleClick={() => onAddResultValue()}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <div className={styles.ObjectiveList}>
                <ul>
                    {stateValuesList}
                </ul>
            </div>
            <p style={{ color: data.length === 0 && 'red' }}>{props.tip}</p>
        </Fragment>
    )
}

export default ListValues;