import React from 'react';
import { Rating } from '@material-ui/lab';
import { P } from 'components/Shared/Theme';
import { ProfilePicture } from 'components/Shared/UI';
import styles from './ReviewDetail.module.css';

const RatingCategory = (props) => {
    return (
        <div className={styles.Rating}>
            <P inline="true">{props.title}</P>
            <Rating
                name="custom-rating-filter-operator"
                placeholder="Filter value"
                disabled
                value={props.rating}
                precision={0.5}
            />
        </div>
    );
}

const ReviewDetail = (props) => {
    const { review } = props;

    return (
        <div className={styles.Wrapper}>
            <div className={styles.Container}>
                <div className={styles.ReviewInfoContainer}>
                    <div className={styles.DatagridProfileImage}>
                        <ProfilePicture userId={review.userId} className={styles.ProfilePicture} />
                    </div>
                    <div className={styles.ReviewInfo}>
                        <div className={styles.ReviewerDetails}>
                            <P inline={'true'} className={styles.FullName}>{review.fullName}</P> <P inline={'true'} className={styles.Date}>{review.updated}</P>
                        </div>
                        <div className={styles.ReviewDescriptionContainer}>
                            <p className={styles.ReviewDescription}>{review.reviewComment}</p>
                        </div>
                    </div>
                </div>

                <div className={styles.ReviewRatingsContainer}>
                    <div className={styles.ReviewRatingSection}>
                        <div className={styles.Header}>
                            <P inline="true">Rating</P>
                        </div>
                        <div className={styles.Ratings}>
                            <ul>
                                <li>
                                    <RatingCategory title={'Accuracy'} rating={review.stars['accuracy']} />
                                </li>
                                <li>
                                    <RatingCategory title={'Currency'} rating={review.stars['currency']} />
                                </li>
                                <li>
                                    <RatingCategory title={'Engaging'} rating={review.stars['engaging']} />
                                </li>
                                <li>
                                    <RatingCategory title={'Enthusiastic'} rating={review.stars['enthusiasm']} />
                                </li>
                                <li>
                                    <RatingCategory title={'Knowledgeable'} rating={review.stars['knowledge']} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.BackButtonContainer}>
                <button className={styles.BackButton} onClick={() => props.onBackToReviewsListHandler()}>Back</button>
            </div>
        </div>
    )
}

export default ReviewDetail;