import React from 'react';
import Button from '@material-ui/core/Button';

const CustomButton = (props) => {

    let classes = [
        props.className
    ];

    if (props.btnDisable) {
        classes.push("btn-disable");
    }

    return (
        <Button {...props}
            style={{ ...props.style }}
            className={classes.join(' ')}
            color={props.color}
            variant={props.variant || 'contained'}>
            {props.children}
        </Button>
    );
}

export default CustomButton;
