import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from 'react-player';
import { Fragment } from 'react';
import styles from './ReplayPresentationVideo.module.css';

const ReplayPresentationVideo = () => {
    const videoForPresentationDataState = useSelector(
        (state) => state.reducer.initialData.videoForPresentationData
    );

    let containerUI = (
        <>
            <Grid item xs={12}>
                <h2>
                    No Module found. Upload a module video to view the replay.
                </h2>
            </Grid>
        </>
    );

    if (videoForPresentationDataState) {
        containerUI = (
            <>
                <Grid
                    item
                    xs={12}
                    className={`${styles.RecordingContainerGrid}`}>
                    <div className={`${styles.VideoOnlyContainer}`}>
                        <ReactPlayer
                            url={videoForPresentationDataState.url}
                            className={`react-player ${styles.ReactPlayerUI}`}
                            width="100%"
                            height="100%"
                            controls
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/presentation-category">
                        <button className="mainButton">Next</button>
                    </Link>
                </Grid>
            </>
        );
    }

    return (
        <Fragment>
            <Grid container justify={'center'}>
                <Grid item xs={12} lg={10} xl={10}>
                    {containerUI}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ReplayPresentationVideo;
