import moment from 'moment';
const CommonDateFormat = 'MMM DD, YYYY';
const CommonDateTimeFormat = 'MMM DD, YYYY HH:mm:ss';

export const commonUTCDateFormat = (date) => {
    return moment.utc(date).format(CommonDateFormat);
};

export const commonUTCToLocalDateFormat = (date) => {
    const utcDate = moment.utc(date).toDate();
    const localDate = moment(utcDate).local().format(CommonDateFormat);
    return localDate;
};

export const commonUTCToLocalDateTimeFormat = (date) => {
    const utcDate = moment.utc(date).toDate();
    const localDate = moment(utcDate).local().format(CommonDateTimeFormat);
    return localDate;
};

export const localDateTimeDifference = (dateOne, dateTwo) => {
    return moment(
        commonUTCToLocalDateTimeFormat(dateOne),
        CommonDateTimeFormat
    ).diff(commonUTCToLocalDateTimeFormat(dateTwo), CommonDateTimeFormat);
};
