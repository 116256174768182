import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addNewKeyword } from '../../redux/action/Index';
import { addNewCategory } from '../../redux/action/Index';
import { onInputHandleChange } from '../../redux/action/Index';
import { handleUpload } from '../../redux/action/Index';
import { onRemoveKeyWord } from '../../redux/action/Index';
import { onFindPresentationOwnerSet } from '../../redux/action/ApiIndex';
import { onReferencesChange } from '../../redux/action/Index';
import { onConflictsChange } from '../../redux/action/Index';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import ButtonComp from '../ButtonComp/ButtonComp';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CategoryCheckBoxes from '../CategoryCheckBoxes/CategoryCheckBoxes';
import KeywordsComponent from '../KeywordsComponent/KeywordsComponent';
import CustomizedDialogBox from '../CustomizedDialogBox/CustomizedDialogBox';
import ListValues from './ListValues';
import Loader from 'components/LoaderComp/LoaderComp';
import styles from './CategoryPricePage.module.scss';
import '../../style/components/CategoryPricePage.scss';

const classes = {
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: 'rgba(149, 170, 201, 1)',
        fontSize: '1.25rem',
        fontWeight: 'normal',
        fontStyle: 'normal'
    }
};

const BlueRadio = withStyles({
    root: {
        color: '#01539D',
        '&$checked': {
            color: '#01539D'
        }
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);

class CategoryPricePage extends Component {
    state = {
        isCelebrateDialogVisible: false,
        hasAdditionalReferenceFiles: false
    };

    onChangeAdditionalReferenceChoiceSelection = (event) => {
        const selectionValue = event.target.value === 'true' ? true : false;
        this.setState({
            hasAdditionalReferenceFiles: selectionValue
        });
    };

    setIsCelebrateDialogVisible = () => {
        const {
            state: { initialData }
        } = this.props;

        if (this.state.hasAdditionalReferenceFiles) {
            this.props.history.push('/presentation/upload-reference-files/');
        } else if (initialData.userProfile.data.newPresenter) {
            this.setState({ isCelebrateDialogVisible: true });
        } else {
            this.props.handleUpload('upload');
        }
    };

    componentDidMount() {
        this.props.onFindPresentationOwnerSet();
    }

    componentDidUpdate(prevProps) {
        const { submitPresentationDataState } = this.props;

        if (
            !submitPresentationDataState.loading &&
            prevProps.submitPresentationDataState.loading
        ) {
            if (!this.state.isCelebrateDialogVisible) {
                this.props.history.push(
                    '/presentations/dashboard/presentations'
                );
            } else {
                this.props.history.push('/user/profile');
            }
        }
    }

    render(props) {
        // eslint-disable-next-line no-unused-vars
        const { classes } = this.props;
        const {
            state: { initialData },
            ApplicationText: { categoryPricePage }
        } = this.props;
        const { submitPresentationDataState } = this.props;

        let containerUI = (
            <>
                <div className="categoryPricePage__div1">
                    <h1 className={`${styles.Heading}`}>
                        {categoryPricePage.categoryHeading}
                    </h1>
                    <p className={`${styles.Description}`}>
                        {categoryPricePage.categoryDescription}
                    </p>
                    <div className="categoryPricePage__div1__input">
                        <CategoryCheckBoxes {...this.props} />
                        <p
                            style={{
                                color:
                                    initialData.category.length === 0 && 'red'
                            }}>
                            {categoryPricePage.categoryTip}
                        </p>
                    </div>
                </div>

                <div className="categoryPricePage__div1">
                    <h1 className={`${styles.Heading}`}>
                        {categoryPricePage.keywordsHeading}
                    </h1>
                    <p className={`${styles.Description}`}>
                        {categoryPricePage.keywordsDescription}
                    </p>
                    <div className="categoryPricePage__div1__input">
                        <KeywordsComponent {...this.props} />
                        <p
                            style={{
                                color:
                                    initialData.keywordsValue.length === 0 &&
                                    'red'
                            }}>
                            {categoryPricePage.keywordsTip}
                        </p>
                    </div>
                </div>

                <div className="categoryPricePage__div1">
                    <h1 className={`${styles.Heading}`}>
                        {categoryPricePage.descriptionHeading}
                    </h1>
                    <p className={`${styles.Description}`}>
                        {categoryPricePage.descriptionDescription}
                    </p>
                    <div className="categoryPricePage__div1__input">
                        <TextField
                            id="CategoryDescriptionInput"
                            placeholder="Description (maximum 2000 characters)"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={initialData.description || ''}
                            onChange={(event) =>
                                this.props.onInputHandleChange(
                                    event.target.value,
                                    'description'
                                )
                            }
                            multiline
                            rows={3}
                            variant="outlined"
                            inputProps={{ maxLength: 2000 }}
                        />
                        <p
                            style={{
                                color: initialData.description === '' && 'red'
                            }}>
                            {categoryPricePage.descriptionTip}
                        </p>
                    </div>
                </div>

                <div
                    className={`categoryPricePage__div1 ${styles.PresentationPurposeSection}`}>
                    <h1 className={`${styles.Heading}`}>
                        {categoryPricePage.referencesHeading}
                    </h1>
                    <p className={`${styles.Description}`}>
                        {categoryPricePage.referencesDescription}
                    </p>
                    <div
                        className={`categoryPricePage__div1__input ${styles.PresentationPurposeSectionInput}`}>
                        <ListValues
                            {...this.props}
                            data={initialData.references}
                            onChange={onReferencesChange}
                            tip={categoryPricePage.referencesTip}
                        />
                    </div>
                </div>

                <div
                    className={`categoryPricePage__div1 ${styles.PresentationPurposeSection}`}>
                    <h1 className={`${styles.Heading}`}>
                        {categoryPricePage.conflictsHeading}
                    </h1>
                    <p className={`${styles.Description}`}>
                        {categoryPricePage.conflictsDescription}
                    </p>
                    <div
                        className={`categoryPricePage__div1__input ${styles.PresentationPurposeSectionInput}`}>
                        <ListValues
                            {...this.props}
                            data={initialData.conflicts}
                            onChange={onConflictsChange}
                            tip={categoryPricePage.conflictsTip}
                        />
                    </div>
                </div>

                <div className={`${styles.AdditionalReferenceFilesSection}`}>
                    <h1 className={`${styles.Heading}`}>
                        {categoryPricePage.additionalReferenceFilesHeading}
                    </h1>
                    <div
                        className={`${styles.AdditionalReferenceFilesConfirmationContainer}`}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="additionalReferenceFiles1"
                                value={this.state.hasAdditionalReferenceFiles}
                                onChange={
                                    this
                                        .onChangeAdditionalReferenceChoiceSelection
                                }>
                                <FormControlLabel
                                    value={false}
                                    control={<BlueRadio />}
                                    label="No"
                                />
                                <FormControlLabel
                                    value={true}
                                    control={<BlueRadio />}
                                    label="Yes"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

                <div
                    style={{ width: '30%', margin: 'auto', marginTop: '40px' }}>
                    {this.state.isCelebrateDialogVisible ? (
                        <CustomizedDialogBox {...this.props} />
                    ) : null}
                    <ButtonComp
                        id={'NextButtonAction'}
                        name={'Next'}
                        handleClick={() => this.setIsCelebrateDialogVisible()}
                        btnDisable={
                            initialData.description !== '' &&
                            initialData.keywordsValue.length > 0 &&
                            initialData.category.length > 0
                                ? false
                                : true
                        }
                    />
                </div>
            </>
        );

        if (submitPresentationDataState.loading) {
            containerUI = <Loader />;
        }

        return <div className="categoryPricePage">{containerUI}</div>;
    }
}

function mapStateToProps(state) {
    return {
        state: state.reducer,
        submitPresentationDataState:
            state.reducer.initialData.submitPresentationData,
        ApplicationText: state.ApplicationText
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addNewKeyword,
            addNewCategory,
            onInputHandleChange,
            handleUpload,
            onRemoveKeyWord,
            onFindPresentationOwnerSet
        },
        dispatch
    );
}

export default withRouter(
    withStyles(classes)(
        connect(mapStateToProps, mapDispatchToProps)(CategoryPricePage)
    )
);
