import React from 'react';
import ReactPlayer from 'react-player';

const VideoPreview = React.memo((props) => {
    const onDuration = (duration) => {};

    const onProgress = (progress) => {
        if (props.isVideoOnly) {
            return;
        }

        const { timestamp } = props;

        let runingTime = Math.floor(progress.playedSeconds);
        let mappedTime = timestamp.map((ele) => ele.time);

        let number = mappedTime.reverse().find((e) => e <= runingTime);

        if (number === undefined) {
            props.onPageUpdate(1);
        }

        if (timestamp.some((item) => item.time === runingTime)) {
            let item = timestamp.find((x) => x.time === runingTime);
            props.onPageUpdate(item.page);
        }
    };

    const { recordedVideo } = props;
    const { width, height } = props;

    return (
        <ReactPlayer
            url={recordedVideo.url}
            className={`react-player ${props.className}`}
            controls
            onDuration={onDuration}
            onProgress={onProgress}
            width={width || '400px'}
            height={height || '100%'}
        />
    );
});

export default VideoPreview;
