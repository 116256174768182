import React, { Fragment, useState } from 'react';
import Box from '@material-ui/core/Box';
import { InputAdornment } from '@material-ui/core';
import ButtonComp from '../../ButtonComp/ButtonComp';
import TextField from '@material-ui/core/TextField';
import { onLearningObjectivesChange } from '../../../redux/action/Index';
import styles from './Objectives.module.scss';
import { useDispatch } from 'react-redux';

const Objectives = (props) => {
    const { classes } = props;
    const [newLearningObjective, setNewLearningObjective] = useState('');
    const [
        isEditingLearningObjectiveIndex,
        setIsEditingLearningObjectiveIndex
    ] = useState(null);
    const [
        isEditingLearningObjectiveValue,
        setIsEditingLearningObjectiveValue
    ] = useState(null);
    const dispatch = useDispatch();

    const {
        state: { initialData }
    } = props;
    const { learningObjectives } = initialData;

    let objectivesListItems = null;

    if (initialData.learningObjectives) {
        objectivesListItems = learningObjectives.map(
            (learningObjective, index) => {
                const commonButtonActionClasses = [];
                let isSaveDisabled = false;
                if (
                    !isEditingLearningObjectiveValue ||
                    isEditingLearningObjectiveValue.trim() === ''
                ) {
                    commonButtonActionClasses.push(styles.Disabled);
                    isSaveDisabled = true;
                }

                let isInEditingMode = false;
                let actionUI = (
                    <>
                        <i
                            className={`fa fa-pencil ${styles.P5} fa-lg ${styles.IconEdit}`}
                            aria-hidden="true"
                            onClick={() =>
                                onStartEditLearningObjective(index)
                            }></i>
                        <i
                            className={`fa fa-times-circle-o fa-lg ${styles.P5} ${styles.IconCross}`}
                            aria-hidden="true"
                            onClick={() =>
                                onDeleteLearningObjective(index)
                            }></i>
                    </>
                );

                if (index === isEditingLearningObjectiveIndex) {
                    isInEditingMode = true;
                    actionUI = (
                        <>
                            <i
                                className={`fa fa-check fa-gray fa-lg ${
                                    styles.P5
                                } ${
                                    styles.IconEdit
                                } ${commonButtonActionClasses.join(' ')}`}
                                aria-hidden="true"
                                onClick={() => {
                                    if (!isSaveDisabled) {
                                        onFinishEditLearningObjective();
                                    }
                                }}></i>
                            <i
                                className={`fa fa-ban fa-lg ${styles.P5} ${styles.IconCross}`}
                                aria-hidden="true"
                                onClick={() =>
                                    onCancelEditLearningObjective()
                                }></i>
                        </>
                    );
                } else if (isEditingLearningObjectiveIndex !== null) {
                    actionUI = null;
                }

                return (
                    <li key={index}>
                        <Box
                            width="100%"
                            style={{
                                justifyContent: 'space-between',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            {!isInEditingMode ? (
                                <span className={styles.ViewObjective}>
                                    {learningObjective}
                                </span>
                            ) : (
                                <TextField
                                    id="EditObjectiveInput"
                                    margin="normal"
                                    fullWidth
                                    classes={{
                                        root: classes.root
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    className={styles.ViewObjective}
                                    value={
                                        isEditingLearningObjectiveValue || ''
                                    }
                                    onChange={(event) =>
                                        onChangeEditLearningObjective(
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 999
                                    }}
                                    InputProps={{
                                        className: classes.input
                                    }}
                                />
                            )}
                            <span>{actionUI}</span>
                        </Box>
                    </li>
                );
            }
        );
    }

    const onChangeNewObjective = (value) => {
        setNewLearningObjective(value);
    };

    const onAddLearningObjective = () => {
        const updatedLearningObjectives = [
            ...learningObjectives,
            newLearningObjective.trim()
        ];
        dispatch(onLearningObjectivesChange(updatedLearningObjectives));
        setNewLearningObjective('');
    };

    const onDeleteLearningObjective = (index) => {
        const updatedLearningObjectives = [...learningObjectives];
        updatedLearningObjectives.splice(index, 1);
        dispatch(onLearningObjectivesChange(updatedLearningObjectives));
    };

    const onStartEditLearningObjective = (index) => {
        const updatedLearningObjectives = [...learningObjectives];
        setIsEditingLearningObjectiveIndex(index);
        setIsEditingLearningObjectiveValue(updatedLearningObjectives[index]);
    };

    const onChangeEditLearningObjective = (value) => {
        setIsEditingLearningObjectiveValue(value);
    };

    const onFinishEditLearningObjective = () => {
        const updatedLearningObjectives = [...learningObjectives];
        updatedLearningObjectives[isEditingLearningObjectiveIndex] =
            isEditingLearningObjectiveValue.trim();
        dispatch(onLearningObjectivesChange(updatedLearningObjectives));
        setIsEditingLearningObjectiveIndex(null);
        setIsEditingLearningObjectiveValue(null);
    };

    const onCancelEditLearningObjective = () => {
        setIsEditingLearningObjectiveIndex(null);
        setIsEditingLearningObjectiveValue(null);
    };

    return (
        <Fragment>
            <TextField
                id="AddObjectiveInput"
                placeholder="Please type here..."
                fullWidth
                margin="normal"
                classes={{
                    root: classes.root
                }}
                InputLabelProps={{
                    shrink: true
                }}
                value={newLearningObjective || ''}
                onChange={(event) => onChangeNewObjective(event.target.value)}
                variant="outlined"
                InputProps={{
                    className: classes.input,
                    endAdornment: (
                        <InputAdornment position="end">
                            <ButtonComp
                                id={'AddObjectiveButton'}
                                name={'Add'}
                                style={{
                                    marginTop: 0,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    paddingRight: 32,
                                    paddingLeft: 32
                                }}
                                btnDisable={
                                    !newLearningObjective ||
                                    newLearningObjective.trim() === '' ||
                                    isEditingLearningObjectiveIndex
                                }
                                handleClick={() => onAddLearningObjective()}
                            />
                        </InputAdornment>
                    )
                }}
            />

            <div className={styles.ObjectiveList}>
                <ul>{objectivesListItems}</ul>
            </div>
            <p style={{ color: learningObjectives.length === 0 && 'red' }}>
                Tip: Add some objectives about the visual module
            </p>
        </Fragment>
    );
};

export default Objectives;
