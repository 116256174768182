import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getQueryParam } from 'utils/router';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import styles from './EditPresentation.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import VideoPreview from '../VideoPreview/VideoPreview';
import { Presentation } from './models';
import LoaderComponent from 'components/LoaderComp/LoaderComp';
import Button from 'components/Shared/UI/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { onTitleChange } from 'redux/action/Index';
import { onInputHandleChange } from 'redux/action/Index';
import { requestPresentationData } from 'redux/action/Index';
import { updatePresentationInfoSend } from 'redux/action/Index';
import { onPresentationInfoReset } from 'redux/action/Index';
import { removePresentationAction } from 'redux/action/Index';
import { removePresentationReset } from 'redux/action/Index';
import { ConfirmationDialog } from 'components/Shared/UI';
import defaultPresentationThumbnailImage from '../../assets/images/samples/sample-presentation-large.png';
const presentationThumbnailImage = (id) =>
    `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;

const classes = {
    root: {
        color: '#F9B041',
        border: '1px solid #F9B041',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '16px',
        paddingBottom: '16px',
        width: '75%',
        display: 'block',
        margin: '0px auto',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px -2px rgba(0, 0, 0, 16%)',
        borderRadius: '5px',
        fontSize: 'clamp(0.875rem, 0.85vw, 1rem)',
        fontWeight: 600
    },
    actionButtonSave: {
        color: '#FFFFFF',
        background: '#F9B041',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        width: '20%'
    },
    actionButtonCancel: {
        color: '#FFFFFF',
        background: '#808080',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        width: '20%',
        marginRight: '40px'
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: '#283E59',
        fontSize: '1rem',
        fontWeight: '600',
        fontStyle: 'normal'
    }
}));

const EditPresentation = (props) => {
    const editPresentationText = useSelector(
        (state) => state.ApplicationText.editPresentationPage
    );
    const updatePresentationInfoState = useSelector(
        (state) => state.reducer.initialData.updatePresentationInfo
    );
    const getPresentationDataState = useSelector(
        (state) => state.reducer.initialData.getPresentation
    );
    const videoTitleState = useSelector(
        (state) => state.reducer.initialData.videoTitle
    );
    const descriptionState = useSelector(
        (state) => state.reducer.initialData.description
    );
    const removePresentationState = useSelector(
        (state) => state.reducer.initialData.removePresentation
    );

    const [presentationData, setPresentationData] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const { classes } = props;
    const presentationThumbnailImageRef = useRef(null);
    const [
        isRemovePresentationRequestSent,
        setIsRemovePresentationRequestSent
    ] = useState(false);
    const [isRemovePresentationDialogOpen, setIsRemovePresentationDialogOpen] =
        useState(false);
    const [isRedoPresentationDialogOpen, setIsRedoPresentationDialogOpen] =
        useState(false);
    const [thumbFile, setThumbFile] = useState(null);
    const [thumbPreview, setThumbPreview] = useState(null);

    const materialStyles = useStyles();
    const { id } = useParams();
    let location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(removePresentationReset());
        dispatch(requestPresentationData(id));
        const videoTitle = getQueryParam('videoTitle', location.search);
        const description = getQueryParam('description', location.search);

        if (videoTitle && !videoTitleState) {
            dispatch(onTitleChange(videoTitle));
        }
        if (description && !descriptionState) {
            dispatch(onInputHandleChange(description, 'description'));
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getPresentationDataState.data) {
            setPresentationData(
                new Presentation(getPresentationDataState.data)
            );
        }
    }, [getPresentationDataState.data]);

    useEffect(() => {
        const { loading, success } = removePresentationState;
        if (!loading && success && isRemovePresentationRequestSent) {
            dispatch(removePresentationReset());
            history.push('/presentations/dashboard/presentations');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removePresentationState.loading, removePresentationState.success]);

    const onPageUpdate = (pageNumber) => {
        setCurrentPageNumber(pageNumber);
    };

    const redoPresentation = () => {
        history.push({
            pathname: '/recordingpage',
            search:
                '?' + new URLSearchParams({ isEditing: true, id }).toString()
        });
    };

    const updatePresentation = async () => {
        await dispatch(updatePresentationInfoSend(id, thumbFile));
        history.push('/presentations/dashboard/presentations');
    };

    const getPresentationThumbnailImage = () => {
        if (id) {
            return presentationThumbnailImage(id);
        }
        return defaultPresentationThumbnailImage;
    };

    const onRemovePresentationHandler = () => {
        setIsRemovePresentationRequestSent(true);
        dispatch(removePresentationAction(id));
    };

    const onCloseRemovePresentationDialogAction = () => {
        setIsRemovePresentationDialogOpen(false);
    };

    const onConfirmRemovePresentationDialogAction = () => {
        onRemovePresentationHandler();
        setIsRemovePresentationDialogOpen(false);
    };

    const onCloseRedoPresentationDialogAction = () => {
        setIsRedoPresentationDialogOpen(false);
    };

    const onConfirmRedoPresentationDialogAction = () => {
        redoPresentation();
        setIsRedoPresentationDialogOpen(false);
    };

    const onChangeThumbnail = (e) => {
        const fileName = e.target.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
            setThumbPreview({
                src: reader.result,
                fileName: fileName,
                profileImageError: false
            });
        };
        reader.readAsDataURL(e.target.files[0]);
        setThumbFile(e.target.files[0]);
    };

    let containerUI = <LoaderComponent />;

    if (presentationData && !getPresentationDataState.loading) {
        containerUI = (
            <Grid container direction="row" justify="center">
                <Grid item xl={12} lg={10}>
                    <p className={styles.Title}>{editPresentationText.title}</p>
                </Grid>

                <Grid item xl={12} lg={10} className={`${styles.Container}`}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className={styles.PresentationSection}>
                        <Grid item lg={8} style={{ paddingRight: '10px' }}>
                            <label htmlFor="thumbnail-upload">
                                {thumbPreview ? (
                                    <img
                                        alt={'Module Thumbnail'}
                                        className={
                                            styles.PresentationThumbnailImage
                                        }
                                        src={thumbPreview.src}
                                    />
                                ) : (
                                    <img
                                        alt={'Module Thumbnail'}
                                        className={
                                            styles.PresentationThumbnailImage
                                        }
                                        ref={presentationThumbnailImageRef}
                                        src={getPresentationThumbnailImage()}
                                        onError={() => {
                                            presentationThumbnailImageRef.current.src =
                                                defaultPresentationThumbnailImage;
                                        }}
                                    />
                                )}

                                <span className={styles.ThumbnailLabel}>
                                    Click on the image to choose a file to
                                    upload for thumbnail.
                                </span>
                            </label>
                            <input
                                id="thumbnail-upload"
                                type="file"
                                accept=".jpg,.jpeg"
                                onChange={onChangeThumbnail}
                            />
                        </Grid>

                        <Grid item lg={4}>
                            <div className={styles.VideoPreviewSection}>
                                <VideoPreview
                                    timestamp={presentationData.timestamps}
                                    recordedVideo={
                                        presentationData.recordedVideo
                                    }
                                    isVideoOnly={presentationData.isVideoOnly}
                                    onPageUpdate={onPageUpdate}
                                    className={styles.ReactPlayerUI}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <Button
                                    variant="outlined"
                                    className={classes.root}
                                    style={{
                                        marginTop: '5%',
                                        marginBottom: '5%'
                                    }}
                                    onClick={() =>
                                        setIsRedoPresentationDialogOpen(true)
                                    }>
                                    Redo Module
                                </Button>
                                <Button
                                    variant="outlined"
                                    className={classes.root}
                                    onClick={() =>
                                        setIsRemovePresentationDialogOpen(true)
                                    }>
                                    Remove Module
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="space-between">
                        <Grid item lg={12}>
                            <div className={`${styles.TitleSection}`}>
                                <span className={styles.Title}>Title:</span>
                                <span className={styles.ToolTip}>
                                    Click inside the box to edit
                                </span>
                                <div className={`${styles.TitleSectionInput}`}>
                                    <Box width="100%">
                                        <TextField
                                            classes={{
                                                root: materialStyles.root
                                            }}
                                            id="outlined-full-width"
                                            placeholder="Please type here..."
                                            multiline
                                            InputProps={{
                                                className: materialStyles.input
                                            }}
                                            style={{ width: '100%' }}
                                            value={videoTitleState || ''}
                                            onChange={(event) => {
                                                dispatch(
                                                    onTitleChange(
                                                        event.target.value
                                                    )
                                                );
                                            }}
                                            rows={6}
                                            variant="outlined"
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={12}>
                            <div className={`${styles.DescriptionSection}`}>
                                <span className={styles.Title}>
                                    Description:
                                </span>
                                <span className={styles.ToolTip}>
                                    Click inside the box to edit
                                </span>
                                <div
                                    className={`${styles.DescriptionSectionInput}`}>
                                    <Box width="100%">
                                        <TextField
                                            classes={{
                                                root: materialStyles.root
                                            }}
                                            id="outlined-full-width"
                                            placeholder="Please type here..."
                                            multiline
                                            InputProps={{
                                                className: materialStyles.input
                                            }}
                                            style={{ width: '100%' }}
                                            value={descriptionState || ''}
                                            onChange={(event) =>
                                                dispatch(
                                                    onInputHandleChange(
                                                        event.target.value.trimStart(),
                                                        'description'
                                                    )
                                                )
                                            }
                                            rows={6}
                                            variant="outlined"
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={12}>
                            <div className={`${styles.ActionSection}`}>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtonCancel}
                                    onClick={() => {
                                        dispatch(onPresentationInfoReset());
                                        history.push(
                                            '/presentations/dashboard/presentations'
                                        );
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtonSave}
                                    onClick={updatePresentation}
                                    disabled={
                                        !videoTitleState.trim() ||
                                        !descriptionState.trim()
                                    }>
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    if (
        (!getPresentationDataState.loading &&
            !getPresentationDataState.success) ||
        (!getPresentationDataState.loading && getPresentationDataState.error)
    ) {
        containerUI = <h1>No Module Found</h1>;
    }

    if (
        updatePresentationInfoState.loading ||
        removePresentationState.loading
    ) {
        containerUI = <LoaderComponent />;
    }

    return (
        <>
            <Container>
                <Box>{containerUI}</Box>
            </Container>
            <ConfirmationDialog
                dialogMessage={'Remove this module?'}
                isDialogOpen={isRemovePresentationDialogOpen}
                onCloseDialogAction={onCloseRemovePresentationDialogAction}
                onConfirmDialogAction={onConfirmRemovePresentationDialogAction}
            />
            <ConfirmationDialog
                dialogMessage={'Record this video again?'}
                isDialogOpen={isRedoPresentationDialogOpen}
                onCloseDialogAction={onCloseRedoPresentationDialogAction}
                onConfirmDialogAction={onConfirmRedoPresentationDialogAction}
            />
        </>
    );
};

export default withStyles(classes)(EditPresentation);
