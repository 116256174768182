import * as actionTypes from './actionTypes';
import { API } from 'aws-amplify';

export const getPresentationReviewsStart = () => {
    return {
        type: actionTypes.GET_PRESENTATION_REVIEWS_START
    };
};

export const getPresentationReviewsSuccess = (data) => {
    return {
        type: actionTypes.GET_PRESENTATION_REVIEWS_SUCCESS,
        payload: data
    };
};

export const getPresentationReviewsFail = (error) => {
    return {
        type: actionTypes.GET_PRESENTATION_REVIEWS_FAIL,
        payload: { error }
    };
};

export const getPresentationReviewsReset = () => {
    return {
        type: actionTypes.GET_PRESENTATION_REVIEWS_RESET
    };
};

export const getPresentationReviews = (id) => {
    return (dispatch, getState) => {
        dispatch(getPresentationReviewsStart());

        API.get(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            `/presentation/${id}/reviews`
        )
            .then((response) => {
                dispatch(getPresentationReviewsSuccess(response));
            })
            .catch((error) => {
                console.log(error.response);
                dispatch(getPresentationReviewsFail(error));
            });
    };
};
