import { Component } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';

class WithScrollTop extends Component {
    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(WithScrollTop)