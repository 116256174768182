import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onPresentationReset } from '../../../redux/action/Index';
import Grid from '@material-ui/core/Grid';
import styles from './PresentationToolBar.module.scss';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';

const REVIEWS = 'reviews';
const REACH = 'reach';
const REPORTS = 'reports';
const PRESENTATIONS = 'presentations';

const PresentationToolBar = (props) => {
    const dispatch = useDispatch();

    const resetPresentationData = () => {
        dispatch(onPresentationReset());
    };

    return (
        <Grid
            container
            item
            xs={12}
            lg={12}
            justify="center"
            alignItems="center">
            <div className={styles.AppBarMenu}>
                <NavLink
                    to={`/presentations/dashboard/${PRESENTATIONS}`}
                    activeClassName={styles.ActiveNavLink}>
                    <p>Modules</p>
                </NavLink>
                <NavLink
                    to={`/presentations/dashboard/${REACH}`}
                    activeClassName={styles.ActiveNavLink}>
                    <p>Reach</p>
                </NavLink>
                <NavLink
                    to={`/presentations/dashboard/${REPORTS}`}
                    activeClassName={styles.ActiveNavLink}>
                    <p>Reports</p>
                </NavLink>
                <NavLink
                    to={`/presentations/dashboard/${REVIEWS}`}
                    activeClassName={styles.ActiveNavLink}>
                    <p>Reviews</p>
                </NavLink>
            </div>

            <NavLink to="/presentation-upload/">
                <ButtonComp
                    name={'New Module'}
                    style={{
                        marginTop: 0,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 24,
                        paddingLeft: 24
                    }}
                    className={styles.Button}
                    onClick={() => resetPresentationData()}
                />
            </NavLink>
            <NavLink to="/new-course/">
                <ButtonComp
                    name={'New Course'}
                    style={{
                        marginTop: 0,
                        marginLeft: 16,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 16,
                        paddingLeft: 16
                    }}
                    className={styles.Button}
                    onClick={() => resetPresentationData()}
                />
            </NavLink>
        </Grid>
    );
};

export default PresentationToolBar;
