import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from '@material-ui/core/Chip';
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="6" fill="#D2DDEC" />
    <path d="M6.90234 11.25L10.6523 15L17.4023 8.25" stroke="white" strokeWidth="2" />
</svg>;
const checkedIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="6" fill="#2C7BE5" />
    <path d="M6.90234 11.25L10.6523 15L17.4023 8.25" stroke="white" strokeWidth="2" />
</svg>


export default function CategoryCheckBoxes(props) {

    const { initialData } = props.state
    return (
        <Autocomplete
            multiple
            id="CategoriesCheckboxes"
            options={initialData.Category}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            onChange={(e, v) => props.addNewCategory(v)}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.title}
                        deleteIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="6" fill="#D2DDEC" />
                            <path d="M8 8L12 12M12 12L16 8M12 12L8 16M12 12L16 16" stroke="#283E59" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        }
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.title}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    // label="Categories"
                    placeholder="Categories"

                />
            )}
        />
    );
}
