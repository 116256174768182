import _ from 'lodash';
import { localDateTimeDifference } from 'utils/date';

export const getSortedAscReviews = (reviews) => {
    let sortedReviews = reviews && reviews instanceof Array ? _.cloneDeep(reviews) : [];
    sortedReviews = sortedReviews.sort((reviewOne, reviewTwo) =>
        localDateTimeDifference(reviewOne.updated, reviewTwo.updated)
    );

    return sortedReviews;
}