import React from 'react';
import FileUploadStatus from './FileUploadStatus/FileUploadStatus';
import Button from '@material-ui/core/Button';
import styles from './FilesUploadStatusWrapper.module.css';

const UploadFileCriteriaList = [
    'Module file size should not be more than 100MB.',
    'You can upload a complete video if you want to skip the recording step.',
    'Video file size should not be more than 5GB.'
];

const UploadFileCriteria = () => {
    return (
        <ul className={styles.UploadFileCriteriaContainer}>
            {UploadFileCriteriaList.map((criteria, criteriaIndex) => {
                return (
                    <li
                        key={criteriaIndex}
                        className={styles.UploadFileCriteriaListItem}>
                        <img
                            src={require('../../../assets/images/additional-reference-files-upload/list-item-icon.svg')}
                            alt={'Bullet Icon'}
                        />
                        <span className={styles.UploadCriteriaDescription}>
                            {criteria}
                        </span>
                    </li>
                );
            })}
        </ul>
    );
};

const FilesUploadStatusWrapper = ({
    presentationFilesToUpload,
    presentationFilesState,
    presentationFileUploadingState,
    presentationFileUploadingStatusState,
    presentationFilesFailedToUploadState,
    onDiscardUploadedPresentationFilesHandler,
    onDiscardErrorFileHandler,
    onRemoveUploadedFileHandler
}) => {
    return (
        <div className={styles.Container}>
            {presentationFilesToUpload.map((file, fileIndex) => {
                let progress = file.progress;
                if (
                    file.fileName ===
                    presentationFileUploadingState.data.fileName
                ) {
                    progress = presentationFileUploadingState.data.progress;
                }
                if (
                    presentationFilesState.findIndex(
                        (presentationFile) =>
                            presentationFile.fileName === file.fileName
                    ) > -1
                ) {
                    progress = 100;
                }

                if (
                    presentationFilesFailedToUploadState.findIndex(
                        (faileFile) => faileFile.fileName === file.fileName
                    ) > -1
                ) {
                    file.error.failedToUploadFile = true;
                }

                file = {
                    ...file,
                    progress
                };
                return (
                    <FileUploadStatus
                        key={fileIndex}
                        {...file}
                        presentationFileUploadingStatusState={
                            presentationFileUploadingStatusState
                        }
                        onDiscardErrorFileHandler={onDiscardErrorFileHandler}
                        onRemoveUploadedFileHandler={
                            onRemoveUploadedFileHandler
                        }
                    />
                );
            })}

            <p className={styles.UploadingTips}>Uploading tips</p>

            <UploadFileCriteria />

            <Button
                id={'discard-presentation-file-upload'}
                className={styles.DiscardButton}
                variant="outlined"
                color="secondary"
                disabled={
                    presentationFileUploadingStatusState.loading ||
                    !presentationFileUploadingStatusState.success ||
                    presentationFilesState.length === 0
                }
                onClick={() => onDiscardUploadedPresentationFilesHandler()}>
                Discard
            </Button>
        </div>
    );
};

export default FilesUploadStatusWrapper;
