import React, { useEffect } from 'react';
import styles from './Dashboard.module.scss';
import { Grid } from '@material-ui/core';
import ButtonComp from '../ButtonComp/ButtonComp';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import PresentationToolBar from '../../containers/PresentationPanel/PresentationToolBar';
import LatestPresentation from './LatestPresentation';
import LatestReports from './LatestReports';
import { BiInfoCircle } from 'react-icons/bi';
import { getPresentations } from 'redux/action/Index';
import LocationMap from './LocationMap';
import { isNumberValueEmpty } from 'utils/Numbers';
import { getShortDurationFromSeconds } from 'utils/time';
import { P } from 'components/Shared/Theme';
import _ from 'lodash';

const getCleanedGeoSetData = (presData = []) => {
    const cleanup = presData.filter((item) => {
        if (item.geoDataSet) {
            return true;
        }
        return false;
    });

    let data = [];

    cleanup.forEach((item) => {
        if (item.geoDataSet.length > 0) {
            for (let x of item.geoDataSet) {
                try {
                    const parsed = JSON.parse(x);
                    if (parsed.city) {
                        data.push(parsed);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            try {
                const parsed = JSON.parse(item.geoDataSet);
                if (parsed.city) {
                    data.push(parsed);
                }
            } catch (error) {
                console.log(error);
            }
        }
    });

    return data;
}

const processLast30DaysReport = (presentations, presentationReportLast30Days) => {
    for (let index = 0; index < presentations.length; index++) {
        const element = presentations[index];

        // Split duration & convert to seconds
        const durations = element.videoDuration.split(':');
        durations[0] = +durations[0] * 60 * 60;
        durations[1] = +durations[1] * 60;
        const duration = durations[0] + durations[1];

        presentationReportLast30Days[0].viewCount += element.geoDataSet ? element.geoDataSet.length : 0;
        presentationReportLast30Days[1].viewCount += +element.viewCount ? (+element.viewCount * duration) : 0;
    }
    presentationReportLast30Days[1].viewCount = Math.trunc(presentationReportLast30Days[1].viewCount);

    presentationReportLast30Days[0].viewCount = isNumberValueEmpty(presentationReportLast30Days[0].viewCount) ? '-' : presentationReportLast30Days[0].viewCount;
    presentationReportLast30Days[1].viewCount = !Number.isInteger(presentationReportLast30Days[1].viewCount) ? '-' : getShortDurationFromSeconds(presentationReportLast30Days[1].viewCount);
}

const DashboardMap = (props) => {
    const { data } = props;

    let viewerLocations;
    let points = [];

    if (data && data.length > 0) {
        let tempViews = {};
        for (let val of data) {
            const newKey = val.city + ', ' + val.countryRegion;
            if (tempViews[newKey]) {
                tempViews[newKey] += 1;
            } else {
                tempViews[newKey] = 1;
            }
        }

        viewerLocations = Object.keys(tempViews)
            .map((key) => {
                return { location: key, viewerCount: tempViews[key] };
            })
            .sort(function (a, b) {
                return a.viewerCount > b.viewerCount;
            });

        const presentationUser = data ? data.slice(0, 2000) : [];
        points = presentationUser.map((mapData) => ({
            type: 'Feature',
            properties: {
                cluster: false,
                mapId: mapData.id
            },
            geometry: {
                type: 'Point',
                coordinates: [parseFloat(mapData.lon), parseFloat(mapData.lat)]
            }
        }));
    }

    return (
        <Grid item lg={12} className={`${styles.ViewerLocationContainer}`}>
            <Grid container direction="row" justify="space-between">
                {points ? (
                    <Grid item lg={8} xs={12} className={styles.MapContainer}>
                        <LocationMap points={points} />
                    </Grid>
                ) : null}

                <Grid
                    item
                    lg={4}
                    xs={12}
                    className={styles.ViewerLocationAnalytics}>
                    <div>
                        <P inline="true" className={styles.ContainerTitle}>
                            Viewer Location
                        </P>
                        <P inline="true" className={styles.ContainerSubTitle}>
                            Last 30 days - views
                        </P>

                        {viewerLocations && viewerLocations.length > 0 ? (
                            <ul style={{ padding: 0 }}>
                                {viewerLocations.map(
                                    (viewerLocation, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                            <P inline="true"
                                                className={
                                                    styles.LocationLabel
                                                }>
                                                {viewerLocation.location}
                                            </P>
                                            <P inline="true"
                                                className={
                                                    styles.LocationCount
                                                }>
                                                {viewerLocation.viewerCount}
                                            </P>
                                        </li>
                                    )
                                )}
                            </ul>
                        ) : null}
                    </div>

                    {!viewerLocations ||
                        (viewerLocations instanceof Array &&
                            viewerLocations.length === 0) ? (
                        <div>
                            <div className={`${styles.NoViewersIconContainer}`}>
                                <BiInfoCircle
                                    size={'1.8em'}
                                    color={'rgba(40, 62, 89, 0.54)'}
                                />
                            </div>

                            <div
                                className={`${styles.NoViewersMessageContainer}`}>
                                <P inline="true" className={`${styles.NoViewersMessage}`}>
                                    No Viewers yet
                                </P>
                            </div>
                        </div>
                    ) : null}

                    <NavLink
                        to="/presentations/dashboard/reach"
                        activeClassName={styles.ActiveNavLink}>
                        <ButtonComp
                            name={'View Map'}
                            style={{
                                marginTop: 0,
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingRight: 32,
                                paddingLeft: 32
                            }}
                            className={styles.MapAnalyticsButton}
                        />
                    </NavLink>
                </Grid>
            </Grid>
        </Grid>
    );
};

const Dashboard = (props) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const presentationsState = useSelector(
        (state) => state.reducer.initialData.presentationsData
    );

    useEffect(() => {
        dispatch(getPresentations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const latestPresentationReports = [
        { label: 'Views', fieldName: 'viewCount' },
        { label: 'Watch time (Hours:Minutes)', fieldName: 'watchTime' },
        { label: 'Average watch duration (Hours:Minutes)', fieldName: 'avgWatchTime' }
    ];

    const presentationReportLast30Days = [
        { label: 'Views', viewCount: 0 },
        { label: 'Watch time (Hours:Minutes)', viewCount: 0 }
    ];

    const topPresentationsLast30Days = [];

    const getLatestPresentation = (presentationData) => {
        let presentations = [];
        let presentations30days = [];
        let topPresentations = [];
        let latestPresentation = null;

        if (
            presentationData &&
            presentationData instanceof Array &&
            presentationData.length > 0
        ) {
            presentations = _.cloneDeep(presentationData);
            latestPresentation = presentations[0];
            presentations30days = getDaysAgoData(presentations, 30);
            processLast30DaysReport(presentations30days, presentationReportLast30Days);
            presentations = sortByViews(presentations30days);
            topPresentations = presentations.slice(-3).reverse();

            for (let i = 0; i < topPresentations.length; i++) {
                const { title, viewCount } = topPresentations[i];
                if (viewCount) {
                    topPresentationsLast30Days.push({
                        label: title,
                        views: viewCount
                    });
                }
            }
        }

        return latestPresentation;
    };

    const getDaysAgoData = (data, daysAgo) => {
        let currentDate = new Date();
        let daysAgoDate = new Date(
            Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - daysAgo
            )
        );

        const filteredData = data.filter(
            (item) => new Date(item.createdDate) >= daysAgoDate
        );
        return filteredData;
    };

    const sortByViews = (data) => {
        const cleanedData = data.map((row) => ({
            ...row,
            viewCount: +row.viewCount ? +row.viewCount : 0
        }));
        return cleanedData.sort((a, b) => +a.viewCount - +b.viewCount);
    }
    
     const {
        data: presentationData,
        loading,
        // error,
        // success
    } = presentationsState;

    const latestPresentation = getLatestPresentation(presentationData);
    const cleanedData = getCleanedGeoSetData(presentationData);

    return (
        <Grid container direction="row" justify="center">
            <Grid item lg={9}>
                <PresentationToolBar />
            </Grid>

            <Grid item lg={9}>
                <Grid container direction="row" justify="space-between">
                    {/* Dashboard Title */}
                    <Grid item lg={12}>
                        <p className={styles.Title}>Dashboard</p>
                    </Grid>

                    {/* Latest Presentation */}
                    <Grid
                        item
                        lg={6}
                        className={`${styles.LatestPresentationContainer}`}>
                        <LatestPresentation
                            latestPresentationReports={
                                latestPresentationReports
                            }
                            data={latestPresentation}
                            loading={loading}
                        />
                    </Grid>

                    {/* Reports */}
                    <Grid item lg={6} className={`${styles.ReportsContainer}`}>
                        <LatestReports
                            loading={loading}
                            presentationReportLast30Days={
                                presentationReportLast30Days
                            }
                            topPresentationsLast30Days={
                                topPresentationsLast30Days
                            }
                            data={latestPresentation}
                        />
                    </Grid>

                    {/* Viewers Locations */}
                    <DashboardMap data={cleanedData} />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.reducer,
        ApplicationText: state.ApplicationText
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
