import React from 'react';
import { Grid } from '@material-ui/core';
import Loader from 'components/LoaderComp/LoaderComp';
import styles from './LatestPresentation.module.scss';
import { P } from 'components/Shared/Theme';
import { getShortDurationFromSeconds } from 'utils/time';
import defaultPresentationThumbnailImage from '../../../assets/images/samples/sample-dashboard-video.png';
const presentationThumbnailImage = (id) =>
    `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;

const LatestPresentation = (props) => {
    const { latestPresentationReports } = props;
    const { data, loading } = props;

    const getProcessedFieldValue = (field) => {
        switch (field) {
            case 'watchTime':
                if (Number.isInteger(data[field])) {
                    // Convert watchtime from minutes to seconds
                    return getShortDurationFromSeconds(data[field] * 60);
                }
                return '-';

            case 'avgWatchTime':
                if (Number.isInteger(data[field])) {
                    // Convert avgWatchTime from minutes to seconds
                    return getShortDurationFromSeconds(data[field] * 60);
                }
                return '-';

            default:
                return data[field];
        }
    };

    let containerUI = (
        <>
            <Grid item lg={12} className={`${styles.PaddingContainerGrid}`}>
                <P inline="true" className={styles.ContainerTitle}>
                    Latest Module
                </P>
                <P inline="true" className={styles.ContainerSubTitle}>
                    Last 30 days
                </P>
            </Grid>

            <Grid
                item
                lg={12}
                className={`${styles.PaddingContainerNoResultGrid}`}>
                <img
                    style={{
                        width: '100%',
                        height: 'fit-content',
                        maxHeight: 250
                    }}
                    alt="Video"
                    className={`${styles.NoResultImage}`}
                    src={require('../../../assets/images/no-result/lady-standing-2.svg')}></img>
            </Grid>

            <Grid
                item
                xs={12}
                lg={12}
                className={`${styles.PaddingContainerNoResultGrid} ${styles.NoResultMessageContainer}`}>
                <span className={styles.NoResultMessage}>
                    You dont have any modules, Click
                    <br />
                    "New Module" to record one
                </span>
            </Grid>
        </>
    );

    if (data) {
        containerUI = (
            <>
                <Grid item lg={12} className={`${styles.PaddingContainerGrid}`}>
                    <P inline="true" className={styles.ContainerTitle}>
                        Latest Module
                    </P>
                    <P inline="true" className={styles.ContainerSubTitle}>
                        Last 30 days
                    </P>
                </Grid>

                <Grid item lg={12} className={`${styles.PaddingContainerGrid}`}>
                    <img
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: 300
                        }}
                        alt="Module Thumbnail"
                        src={presentationThumbnailImage(data.id)}
                        onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defaultPresentationThumbnailImage;
                        }}></img>
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={12}
                    className={`${styles.PaddingContainerGrid}`}>
                    <ul style={{ padding: 0 }}>
                        {latestPresentationReports.map((report, index) => (
                            <li
                                key={index}
                                className={styles.LatestPresentationDetails}>
                                <P inline="true">{report.label}</P>
                                <P inline="true">
                                    {getProcessedFieldValue(report.fieldName)}
                                </P>
                            </li>
                        ))}
                    </ul>
                </Grid>
            </>
        );
    }

    if (loading) {
        containerUI = (
            <div className={styles.LoaderContainer}>
                <Loader />
            </div>
        );
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            className={styles.LatestPresentationSubContainer}>
            {containerUI}
        </Grid>
    );
};

export default LatestPresentation;
