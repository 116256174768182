import React from 'react';
import Container from './containers/container';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#01539D'
        }
    }
});

function App(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter basename="/">
                <div className="App">
                    <Container {...props} />
                </div>
            </BrowserRouter>
        </MuiThemeProvider>
    );
}

export default App;
