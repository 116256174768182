import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
// import styles from './Warning.module.css';

const useStyles = makeStyles((theme) => ({
    alertIcon: {
        borderRadius: '10px',
        backgroundColor: 'rgba(249, 176, 65, 0.5) !important',
        '& .MuiAlert-icon': {
            color: '#000000'
        },
        '& .MuiAlert-message': {
            color: '#000000',
            fontSize: '1rem',
            fontWeight: 400
        }
    }
}));

const Warning = ({ styles = {}, message }) => {
    const classes = useStyles();

    return (
        <Alert
            severity="warning"
            className={classes.alertIcon}
            style={{
                width: 'fit-content',
                ...styles
            }}>
            {message}
        </Alert>
    )
}

export default Warning;